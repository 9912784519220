const OriginEnum = Object.freeze({
  WEBAPP_LOCAL_HOST: "web-app-local-host",
  WEBAPP_ALPHA: "web-app-alpha",
  WEBAPP_BETA: "web-app-beta",
  WEBAPP_PROD: "web-app-prod",
  WEBAPP_STAGE_BETA: "web-app-beta-stage",
  WEBAPP_LOOP_ALPHA: "web-app-alpha-loop",
  WEBAPP_LOOP_BETA: "web-app-beta-loop",
  DTA: "dta",
  INVITE: "invite",
  MOBILE: "mobile",
  MOBILE_V2: "mobile-v2",
  MARKETING: "marketing",
});

export default OriginEnum;
