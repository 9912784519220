<template>
  <div>
    <b-row style="padding-top: 60px" class="justify-content-center">
      <b-col sm="12" md="9" lg="12">
        <EmailInput
          id="email"
          :field="$v.form.email"
          :state="validateState($v.form, 'email')"
          @update="$v.form.email.$model = $event"
          @keyupEnter="newEmail"
          label="Email"
          field-name="email"
        />
        <PasswordInput
          id="password"
          :field="$v.form.password"
          :state="validateState($v.form, 'password')"
          @update="$v.form.password.$model = $event"
          @keyupEnter="newEmail"
          label="Password"
          field-name="password"
        />
        <PasswordInput
          id="password-repeat"
          :field="$v.form.repeatPassword"
          :state="validateState($v.form, 'repeatPassword')"
          @update="$v.form.repeatPassword.$model = $event"
          @keyupEnter="newEmail"
          label="Repeat password"
          field-name="repeat password"
        />
      </b-col>
    </b-row>
    <b-row class="mt-4 justify-content-center">
      <b-col sm="12" md="9" lg="12">
        <SubmitButton :loading="loading" text="Confirm" @onClick="newEmail" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PasswordInput from "@/components/inputs/PasswordInput";
import SubmitButton from "@/components/buttons/SubmitButton";
import EmailInput from "@/components/inputs/EmailInput";
import changeEmail from "@/mixins/loopLogin/changeEmail";
export default {
  name: "ChangeEmail",
  components: { EmailInput, SubmitButton, PasswordInput },
  mixins: [changeEmail],
};
</script>
