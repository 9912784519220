import { common } from "@/services/common/common";

export default {
  namespaced: true,
  state: {
    logRequestId: localStorage.sessionId,
    referralPage: localStorage.getItem("referralPage") || "",
  },
  mutations: {
    setReferralPage: (state, referralPage) => {
      localStorage.referralPage = referralPage;
      state.referralPage = referralPage;
    },
  },
  getters: {
    getSessionId: (state) => {
      if (!state.logRequestId) {
        localStorage.sessionId = common.makeId(16);
        state.logRequestId = localStorage.sessionId;
      }
      return state.logRequestId;
    },
  },
};
