<template>
  <div class="ellipse" />
</template>

<script>
export default {
  name: "EllipseElement",
};
</script>

<style lang="scss" scoped>
.ellipse {
  width: 61.98px;
  height: 108.47px;

  background: #236859;
  border-radius: 104.491px;
  transform: rotate(-30deg);
  z-index: 2;
}
</style>
