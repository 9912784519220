import { oauth } from "@/services/oauth";
import router from "@/router";
import { shared_inbox } from "@/api/command/api/shared_inbox";
import { users } from "@/api/users";
import { mix_panel } from "@/services/common/mixpanel";
export default {
  namespaced: true,
  state: {
    // PERSONAL items
    accessToken: localStorage.getItem("accessToken") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
    strategy: localStorage.getItem("strategy"),
    user: JSON.parse(localStorage.getItem("user")) || null,
    imapData: JSON.parse(localStorage.getItem("imapData")) || {},
    isSharedInbox: localStorage.getItem("isSharedInbox") || "false",
    // END

    email: localStorage.getItem("email") || null,
    firstEmail: localStorage.getItem("firstEmail") || null,
    key: localStorage.getItem("key") || null,
    purpose: localStorage.getItem("purpose") || null,
    alreadyRegistered:
      JSON.parse(localStorage.getItem("alreadyRegistered")) || false,
  },
  mutations: {
    // PERSONAL ITEMS
    updateAccessToken: (state, accessToken) => {
      localStorage.accessToken = accessToken;
      state.accessToken = accessToken;
    },
    updateRefreshToken: (state, refreshToken) => {
      localStorage.refreshToken = refreshToken;
      state.refreshToken = refreshToken;
    },
    updateUser: (state, user) => {
      localStorage.user = JSON.stringify(user);
      state.user = user;
    },
    updateImapData: (state, imapData) => {
      localStorage.imapData = JSON.stringify(imapData);
      state.imapData = imapData;
    },
    setStrategy: (state, strategy) => {
      localStorage.strategy = strategy;
      state.strategy = strategy;
    },
    // END
    updateEmail: (state, email) => {
      localStorage.email = email;
      mix_panel.identify(email);
      state.email = email;
    },
    updateFirstEmail: (state, firstEmail) => {
      localStorage.firstEmail = firstEmail;
      state.firstEmail = firstEmail;
    },
    updateKey: (state, key) => {
      localStorage.key = key;
      state.key = key;
    },
    updatePurpose: (state, purpose) => {
      localStorage.purpose = purpose;
      state.purpose = purpose;
    },
    updateIsRegistered: (state, alreadyRegistered) => {
      localStorage.alreadyRegistered = JSON.stringify(alreadyRegistered);
      state.alreadyRegistered = alreadyRegistered;
    },
  },
  getters: {
    // PERSONAL
    user: (state) => state.user,
    strategy: (state) => state.strategy,
    accessToken: (state) => state.accessToken,
    refreshToken: (state) => state.refreshToken,
    isAuthenticated: (state) => !!state.accessToken,
    imapData: (state) => state.imapData,
    // END
    email: (state) => state.email,
    firstEmail: (state) => state.firstEmail,
    key: (state) => state.key,
    purpose: (state) => state.purpose,
  },
  actions: {
    async register({ state, commit }, [type, email]) {
      if (email) {
        commit("updateUserEmail", email);
      }
      // if auth, we don't want to show previous code
      localStorage.removeItem("createTime");
      localStorage.removeItem("code");
      if (type === "Microsoft") {
        localStorage.strategy = "microsoft";
        oauth.loginWithMicrosoft(state.email);
      } else if (type === "Google") {
        localStorage.strategy = "google";
        oauth.loginWithGoogle(state.email);
      } else if (type === "Exchange") {
        localStorage.strategy = "Exchange";
        await router.push(`${router.history.current.path}/exchange`);
      } else if (type === "Imap") {
        localStorage.strategy = "Imap";
        await router.push(`${router.history.current.path}/imap`);
      } else if (type === "GmailAlias") {
        await router.push("/shared-inbox/4");
        await shared_inbox.createGmailAlias();
      } else if (type === "MicrosoftSI") {
        localStorage.strategy = "microsoftSI";
        await router.push(
          `${router.history.current.path}/microsoft-shared-inbox`
        );
      }
    },
    async getAuthData({ state }, code) {
      if (state.strategy === "microsoft" || state.strategy === "microsoftSI") {
        await oauth.processMicrosoft(code);
      } else {
        await oauth.processGoogle(code);
      }
    },
    logout({ commit }) {
      localStorage.removeItem("accessToken");
      commit("removeTokens");
      commit("logout");
    },
    async updateVerifyData({ commit }, [email, key, purpose]) {
      commit("updateEmail", email);
      commit("updateKey", key);
      commit("updatePurpose", purpose);
    },
    async getUser({ commit }) {
      let response = await users.getUserInfo();
      commit("updateUser", response.data);
    },
  },
};
