import validation from "@/mixins/validation";
import { email, required } from "vuelidate/lib/validators";

export default {
  mixins: [validation],
  data() {
    return {
      form: {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      firstName: {
        required,
      },
      lastName: {},
    },
  },
};
