<template>
  <div>
    <nav
      class="navbar navbar-light justify-content-center justify-content-md-start pr-0"
    >
      <div>
        <Logo class="mt-4 ml-0 ml-md-5" />
      </div>
    </nav>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
export default {
  name: "LogoComponent",
  components: { Logo },
};
</script>
