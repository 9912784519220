import api from "@/client/client";

const route = `/api/v1/token/`;

const clientId = "RegistrationLoopClient";

export const token = {
  getCode() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-ClientId": clientId,
    };
    return api.get(`${route}code`, { headers });
  },
};
