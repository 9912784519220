import FreeDomains from "@/helpers/enums/FreeDomains";
import { tracking_constants } from "@/services/common/constants";

export default {
  methods: {
    getEmailDomainUrlParameter(email) {
      return this.isBusinessDomain(email);
    },
    isBusinessDomain(email) {
      let domain = email.split("@");
      let isBusiness = true;

      // check if domain is not undefined
      if (domain[1]) {
        if (FreeDomains.includes(domain[1])) {
          isBusiness = false;
        }
      }

      let properties = {};
      properties[tracking_constants.DOMAIN_TYPE] =
        tracking_constants.PERSONAL_DOMAIN;

      if (isBusiness) {
        properties[tracking_constants.DOMAIN_TYPE] =
          tracking_constants.BUSINESS_DOMAIN;
      }
      return [isBusiness ? "business" : "personal", properties];
    },
  },
};
