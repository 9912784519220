<template>
  <Signup>
    <template #content>
      <div class="registration-wrapper">
        <div>
          <Logo class="reg-logo" />
          <RegistrationCard />
        </div>
      </div>
    </template>
  </Signup>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { mix_panel } from "@/services/common/mixpanel";
import { tracking_constants, constants } from "@/services/common/constants";
import OriginEnum from "@/helpers/enums/OriginEnum";
import utmParams from "@/mixins/helpers/utmParams";
import mobileRedirect from "../mixins/helpers/mobileRedirect";
import Signup from "../layout/Signup";
import Logo from "../components/Logo";
import RegistrationCard from "../components/newDesign/RegistrationCard";

export default {
  name: "index",
  components: { RegistrationCard, Logo, Signup },
  mixins: [utmParams, mobileRedirect],
  data() {
    return {
      availableOrigins: [
        OriginEnum.DTA,
        OriginEnum.WEBAPP_LOCAL_HOST,
        OriginEnum.WEBAPP_ALPHA,
        OriginEnum.WEBAPP_BETA,
        OriginEnum.MOBILE,
        OriginEnum.MOBILE_V2,
        OriginEnum.INVITE,
      ],
    };
  },
  computed: {
    ...mapState({
      intention: (state) => state.data.intention,
    }),
  },
  mounted() {
    localStorage.clear();
    let properties = {};
    if (this.$route.query.email) {
      this.updateFirstEmail(this.$route.query.email);
      this.updateEmail(this.$route.query.email);
      properties[tracking_constants.EMAIL] = this.$route.query.email;
    }
    // update intention if set in query, else check if in local storage else default
    if (this.$route.query.intention) {
      if (this.$route.query.intention.includes(constants.INTENTION.TEAM)) {
        this.setIntention(constants.INTENTION.TEAM);
      } else if (
        this.$route.query.intention.includes(constants.INTENTION.PERSONAL)
      ) {
        this.setIntention(constants.INTENTION.PERSONAL);
      } else if (
        this.$route.query.intention.includes(constants.INTENTION.SHAREDINBOX)
      ) {
        this.setIntention(constants.INTENTION.SHAREDINBOX);
      } else {
        this.setIntention(constants.INTENTION.DEFAULT);
      }
    } else {
      if (!this.intention) {
        this.setIntention(constants.INTENTION.DEFAULT);
      } else {
        this.setIntention(this.intention);
      }
    }
    properties[tracking_constants.INTENTION] = this.intention;

    // set default origin to webapp-prod
    this.setOrigin(
      this.$route.query.from === undefined
        ? OriginEnum.WEBAPP_PROD
        : this.$route.query.from
    );

    mix_panel.setUserProperties(properties);
  },
  methods: {
    ...mapMutations("auth", ["updateFirstEmail", "updateEmail"]),
    ...mapMutations("data", ["setIntention", "setDownload", "setOrigin"]),
  },
};
</script>

<style lang="scss" scoped></style>
