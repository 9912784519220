<template>
  <div
    v-if="canShow"
    class="text-center d-flex justify-content-center align-items-center h-100"
  >
    <div>
      <img src="@/assets/img/error_pages/404.svg" alt="404" class="ml-1" />
      <div class="main-title f-36 mt-5">Oops! 404 Page Not Found.</div>
      <div class="subtitle mt-3">
        We can't seem to find the page you're looking for. It may have expired,
        or there could be a typo.
        <br />
        You can go back or
        <a
          class="clickable"
          style="color: #2ec0e8"
          :href="`mailto:loopbot@intheloop.io?subject=${subject}&body=${body}: ${getLocation}`"
        >
          contact us
        </a>
        if you need a hand.
      </div>
    </div>
  </div>
</template>

<script>
import { logging } from "@/api/logs";

export default {
  props: {
    error: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      canShow: false,
      subject: "Ups, 404 Page Not Found",
      body: "Hi,%0D%0Athe following link URL was not found",
    };
  },
  computed: {
    getLocation() {
      return window.location.href;
    },
  },
  async mounted() {
    logging.sendLog(`Error page shown ${JSON.stringify(this.error)}`, 3);
    this.canShow = true;
  },
};
</script>
