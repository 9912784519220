<template>
  <LoopLeft>
    <div class="h-100 text-center intro-padding intro align-items-center">
      <div class="intro-heading position-relative mb-5">
        <div>Reset password</div>
      </div>
      <b-row class="intro-subtitle justify-content-center">
        <b-col cols="5">
          <div>
            We sent a reset password email to
            <b>{{ email }}</b>
            . Please check your email and click the reset password link to set
            your new password.
            <br />
            <br />
            Can't find the email in your inbox?
            <br />
            Please check your spam folder, or
            <router-link to="/forgot-password/send-email/">
              try again.
            </router-link>
          </div>
        </b-col>
      </b-row>
    </div>
  </LoopLeft>
</template>

<script>
import LoopLeft from "@/layout/LoopLeft";
export default {
  name: "email-sent",
  components: { LoopLeft },
  computed: {
    email() {
      return this.$route.query.email;
    },
  },
};
</script>
