<template>
  <LoopLeft>
    <div>
      <div class="h-100 text-center intro-padding intro align-items-center">
        <div class="intro-heading position-relative mb-5">
          <div>Reset your password</div>
        </div>
      </div>
      <ForgotPassword />
    </div>
  </LoopLeft>
</template>

<script>
import LoopLeft from "@/layout/LoopLeft";
import ForgotPassword from "@/components/forms/ForgotPassword";
export default {
  name: "index.vue",
  components: {
    ForgotPassword,
    LoopLeft,
  },
};
</script>
