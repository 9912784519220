<template>
  <ProcessLogin strategy="google" />
</template>

<script>
import ProcessLogin from "@/components/oauthLogin/ProcessLogin";
import { mapMutations } from "vuex";
export default {
  name: "google",
  components: { ProcessLogin },
  mounted() {
    this.$store.commit("auth/setStrategy", "google");
  },
  methods: {
    ...mapMutations("data", ["setDownload"]),
  },
};
</script>
