<template>
  <Download class="h-100" />
</template>

<script>
import Download from "@/components/Home/NewDownload/NewDownload";
export default {
  name: "index.vue",
  components: { Download },
};
</script>
