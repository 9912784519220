import api from "../../client/client";
import { store } from "@/store";
import log from "@/client/logClient";
import { logging, logging as logs } from "@/api/logs";
import deviceId from "device-uuid";

api.interceptors.request.use((config) => {
  let token = store.getters["auth/accessToken"];

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
api.interceptors.response.use(
  (response) => {
    if (response.config.url.includes("emailType")) {
      store.commit("data/setEmailAuthType", response.data.emailAuthType);
    }

    let requestHeaders = response.config.headers;
    delete requestHeaders.Authorization;
    let logMessage = `Record every response (INFO): 
    \n${response.config.method} url:${response.config.url} 
    \nHTTP response status: ${response.status}
    \nHTTP response headers: ${JSON.stringify(response.headers)}
    \nHTTP request headers: ${JSON.stringify(requestHeaders)}`;
    if (!response.config.url.includes("auth")) {
      logMessage += `\nHTTP response data: ${response.data}`;
    }
    logging.sendLog(logMessage, 3);
    return response;
  },
  (error) => {
    let requestHeaders = error.config.headers;
    delete requestHeaders.Authorization;
    let logMessage = `Record every response (INFO): 
    \nHTTP ERROR message: ${error.message}
    \n${error.config.method} url:${error.config.url} 
    \nHTTP response status: ${error.response.status}
    \nHTTP response headers: ${JSON.stringify(error.response.headers)}
    \nHTTP request headers: ${JSON.stringify(requestHeaders)}`;
    if (
      error.response.status === 401 ||
      (error.response.status === 404 &&
        error.config.url.includes("settings") &&
        error.config.method === "get")
    ) {
      logging.sendLog(logMessage, 4);
    } else {
      logging.sendLog(logMessage, 6);
    }
    throw error;
  }
);

log.interceptors.request.use((config) => {
  if (!localStorage.machineId) {
    localStorage.machineId = new deviceId.DeviceUUID().get();
  }
  let localTime = new Date().toISOString();
  let encryptedBearer = logs.getCryptedLogBearer(
    `${localStorage.machineId};${localTime}`
  );
  config.headers.Authorization = `LogAuth ${encryptedBearer}`;
  return config;
});
