import api from "@/client/client";

const route = "/api/v1/user/";

export const users = {
  getUserInfo() {
    return api.get(`${route}`);
  },
  putUser(user) {
    return api.put(`${route}`, user);
  },
};
