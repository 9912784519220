import { auth } from "@/api/auth";
import { logging } from "@/api/logs";
import { store } from "@/store";

const reg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
let allSystems = [
  {
    name: "Windows",
    downloadLink: "https://dl.intheloop.io/Loop-Email-Setup.exe",
  },
  {
    name: "Mac",
    downloadLink: "https://dl.intheloop.io/Loop-Email.dmg",
  },
  {
    name: "iOS",
    downloadLink:
      "https://apps.apple.com/si/app/loop-the-email-messenger/id1024152475",
  },
  {
    name: "Android",
    downloadLink:
      "https://play.google.com/store/apps/details?id=com.loop.io&amp;hl=en",
  },
  {
    name: "MacM1",
    downloadLink: "https://dl.intheloop.io/Loop-Email-arm64.dmg",
  },
  {
    name: "Linux",
  },
];
let userAgent = window.navigator.userAgent,
  platform = window.navigator.platform,
  macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
  windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
  iosPlatforms = ["iPhone", "iPad", "iPod"];

export const common = {
  EMAIL_NOT_YET_REGISTERED: "User with this email does not exist.",
  PASSWORD_POLICY_ERROR:
    "The password does not meet the password policy requirements. The minimum length should be 7 characters.",
  IDENTIFICATOR_ALREADY_IN_USE: "EMAIL_USED",
  IDENTIFICATOR_HAS_SYNC_ACC: "EMAIL_HAS_SYNC",
  EMAIL_PASSWORD_REQUIRED: "Please enter your email/password.",
  WRONG_EMAIL_PASSWORD: "The email/password combination is invalid.",
  WRONG_PASSWORD: "The password you've entered is invalid.",
  WRONG_HOST: "Host not found. Name or service is not known.",
  NETWORK_PROBLEM: "There was a problem connecting to Loop Email servers.",
  ACTIVATION_EMAIL_SENT: "Check your email for an activation link.",
  OSes: allSystems,
  parseTokenFromHtml(html) {
    const a = html;
    const first = a.indexOf("{");
    const second = a.indexOf("}");
    const b = a.substring(first, second + 1);
    return JSON.parse(b);
  },
  async getEmailType(email) {
    try {
      store.commit("auth/updateEmail", email);
      let response = await auth.getEmailType(email);
      store.commit("auth/updateAccountType", response.data.emailServiceType);
      logging.sendLog(`EmailHelper response: ${JSON.stringify(response)}`, 3);
      return response;
    } catch (e) {
      logging.sendLog("EmailHelper error", 6);
    }
  },
  isEmail(email) {
    return reg.test(email);
  },
  makeId(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  getOsType() {
    if (
      iosPlatforms.indexOf(platform) !== -1 ||
      (navigator.userAgent.match(/Mac/) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2)
    ) {
      return allSystems.filter((os) => os.name === "iOS")[0];
    } else if (macosPlatforms.indexOf(platform) !== -1) {
      return allSystems.filter((os) => os.name === "Mac")[0];
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      return allSystems.filter((os) => os.name === "Windows")[0];
    } else if (/Android/.test(userAgent)) {
      return allSystems.filter((os) => os.name === "Android")[0];
    } else if (/Linux/.test(platform)) {
      return allSystems.filter((os) => os.name === "Linux")[0];
    } else {
      // default Windows
      return allSystems.filter((os) => os.name === "Windows")[0];
    }
  },
  isAndroidOrIos() {
    let os = this.getOsType();
    return os.name === "Android" || os.name === "iOS";
  },
  delay: (ms) => new Promise((res) => setTimeout(res, ms)),
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
};
