<template>
  <div class="check-marks">
    <div v-for="text in checkMarksArray" :key="text">
      <img
        src="@/assets/img/tick-sign.svg"
        width="15"
        height="15"
        alt="thick"
      />
      <div>{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckMarks",
  props: {
    checkMarksArray: { type: Array, required: true },
  },
};
</script>

<style lang="scss" scoped>
.check-marks {
  display: flex;
  div {
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-size: 13px;

    img {
      margin-right: 6px;
      width: 15px;
      height: 15px;
    }
  }
}
</style>
