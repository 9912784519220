<template>
  <div>
    <div class="d-flex justify-content-center position-relative">
      <img
        src="https://dl.intheloop.io/onboarding/desktop_app.png"
        alt="logo"
        height="170px"
      />
    </div>
    <div class="d-md-flex d-block mt-3">
      <div class="d-flex justify-content-center">
        <a
          class="button-neon download-button-neon mr-md-3 mb-3 mb-md-0"
          @click="download(allSystems[1].name)"
        >
          <Apple size="24" style="height: 24px" />
          Mac (Intel)
        </a>
      </div>
      <div class="d-flex justify-content-center">
        <a
          class="button-neon download-button-neon mr-md-3 mb-3 mb-md-0"
          @click="download(allSystems[4].name)"
        >
          <Apple size="24" style="height: 24px" />
          Mac (M1)
        </a>
      </div>
      <div class="d-flex justify-content-center">
        <a
          class="button-neon download-button-neon"
          @click="download(allSystems[0].name)"
        >
          <MicrosoftWindows />
          Windows
        </a>
      </div>
    </div>
    <a
      style="position: absolute"
      ref="apple"
      :href="allSystems[1].downloadLink"
      download
    />
    <a
      style="position: absolute"
      ref="macM1"
      :href="allSystems[4].downloadLink"
      download
    />
    <a
      style="position: absolute"
      ref="windows"
      :href="allSystems[0].downloadLink"
      download
    />
  </div>
</template>

<script>
import newDownload from "@/mixins/newDownload/newDownload";
import MicrosoftWindows from "vue-material-design-icons/MicrosoftWindows.vue";
import Apple from "vue-material-design-icons/Apple.vue";

export default {
  name: "DesktopApp",
  components: {
    MicrosoftWindows,
    Apple,
  },
  mixins: [newDownload],
};
</script>
