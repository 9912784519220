import api from "@/client/client";
import { queryBuilder } from "@/api/common/queryBuilder";
import { auth } from "@/api/auth";
import { store } from "@/store";
import { common } from "@/services/common/common";
import { logging } from "@/api/logs";
import { encryption } from "@/services/common/encryption";
import router from "@/router";
import { settings } from "@/api/settings";
import { oauth } from "@/services/oauth";
import { isFullScopeSetup, isMicrosoftSharedInboxSetup } from "../oauth";

let strategy = "microsoft";

export const ScopeMicrosoft = {
  DEFAULT:
    "Calendars.ReadWrite Contacts.Read Mail.ReadWrite Mail.Send MailboxSettings.ReadWrite User.Read offline_access",
  SHAREDINBOX:
    "Calendars.ReadWrite Contacts.Read Mail.ReadWrite Mail.Send MailboxSettings.ReadWrite " +
    "User.Read offline_access mail.send.shared mail.readwrite.shared contacts.read.shared " +
    "calendars.readwrite.shared data.readbasic.all",
  LIMITED: "User.Read offline_access",
};

export const microsoft = {
  loginWithMicrosoft(email, scope) {
    localStorage.state = encryption.encrypt(
      `LOOP_EMAIL_STATE:${new Date().getTime()}`
    );
    const args = {
      tenant: "common",
      scope: scope,
      response_mode: "query",
      response_type: "code",
      token_type: "Bearer",
      redirect_uri:
        process.env.VUE_APP_PAGE_LOCATION_URL +
        oauth.getRedirectUrl("microsoft"),
      client_id: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
      token_key: "access_token",
    };
    if (scope === ScopeMicrosoft.SHAREDINBOX) {
      args.prompt = "select_account";
    } else {
      args.prompt = "login";
      if (JSON.parse(store.state.auth.isSharedInbox)) {
        args["login_hint"] = store.state.data.sharedInbox.email;
      } else {
        if (email) {
          args["login_hint"] = email;
        }
      }
    }

    window.location =
      "https://login.microsoftonline.com/common/oauth2/v2.0/authorize" +
      queryBuilder.buildQuery(args);
  },
  async getRefreshTokenMicrosoft(code) {
    try {
      const args = {
        code: code,
        redirect_uri:
          process.env.VUE_APP_PAGE_LOCATION_URL +
          oauth.getRedirectUrl("microsoft"),
        state: localStorage.state
          ? localStorage.state
          : encryption.encrypt(`LOOP_EMAIL_STATE:${new Date().getTime()}`),
        responseForm: "credentials",
      };

      let requestUri = isFullScopeSetup()
        ? "/api/v1.4/token/microsoft"
        : "/api/v1.4/token/microsoftLimited";

      if (isMicrosoftSharedInboxSetup()) {
        args.shared_scope = true;
      }

      let response = await api.get(
        `${requestUri}` + queryBuilder.buildQuery(args)
      );
      const final = common.parseTokenFromHtml(response.data);
      store.commit("auth/updateRefreshToken", final.RefreshToken);
      store.commit("auth/updateAccessToken", final.AccessToken);
      store.commit("auth/updateEmail", final.Email);
    } catch (e) {
      logging.sendLog("getRefreshTokenMicrosoft() error", 6);
      router.push(`/?from=${this.$store.state.data.origin}`);
    }
  },
  async registerMicrosoft() {
    const data = {
      email: store.state.auth.email,
      accessToken: store.state.auth.accessToken,
      $type: "AuthMicrosoft",
    };

    if (isMicrosoftSharedInboxSetup()) {
      // We expect state to have sharedInboxEmail at the start
      // e.g. shared@intheloop.io::microsoft-extended::shared-inbox-setup::...
      data.sharedInboxEmail = decodeURI(localStorage.oauthState).split("::")[0];
    }
    try {
      const response = await auth.registerMicrosoft(data);
      store.commit("auth/updateAccessToken", response.data.token.accessToken);
      // await store.dispatch("auth/getUser");
      await settings.settingsSync(strategy);
    } catch (e) {
      logging.sendLog("registerMicrosoft() error", 6);
      router.push(`/?from=${this.$store.state.data.origin}`);
    } finally {
      localStorage.removeItem("limitedScope");
    }
  },
  async getProfile() {
    const data = {
      email: store.state.auth.email,
      accessToken: store.state.auth.accessToken,
      $type: "AuthMicrosoft",
    };
    try {
      const response = await auth.microsoftProfile(data);
      return response.data;
    } catch (e) {
      logging.sendLog("getGoogleProfile() error", 6);
      router.push(`/?from=${this.$store.state.data.origin}`);
    }
  },
};
