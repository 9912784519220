<template>
  <div>
    <b-row class="justify-content-center">
      <b-col sm="12" md="8" lg="6">
        <EmailInput
          id="email"
          :field="$v.form.email"
          :state="validateState($v.form, 'email')"
          @update="$v.form.email.$model = $event"
          @keyupEnter="sendEmail"
          label="Email"
          field-name="email"
        />
        <div class="d-flex justify-content-center">
          <SubmitButton
            text="Send me email"
            class="mt-4"
            :loading="loading"
            @onClick="sendEmail"
          />
        </div>
      </b-col>
    </b-row>
    <ToastSuccess text="Forgot password email was sent!" />
    <ToastError
      text="There was a problem sending an email. Please try again later."
    >
      <template>
        <div>Error: {{ toastMessage }}</div>
      </template>
    </ToastError>
  </div>
</template>

<script>
import forgotPassword from "@/mixins/loopLogin/forgotPassword";
import ToastSuccess from "@/components/toast/ToastSuccess";
import ToastError from "@/components/toast/ToastError";
import SubmitButton from "@/components/buttons/SubmitButton";
import EmailInput from "@/components/inputs/EmailInput";
export default {
  name: "ForgotPassword",
  components: { EmailInput, SubmitButton, ToastError, ToastSuccess },
  mixins: [forgotPassword],
};
</script>
