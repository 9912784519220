import { command } from "@/api/command/common";
import { store } from "@/store";

export const shared_inbox = {
  async createSharedInbox(type, email) {
    let response = await command.commandRequest(
      `/create shared inbox ${type} ${email} [] true`
    );
    return JSON.parse(response.data.rawResponse).resources;
  },
  async updateSharedInbox(teamId, data) {
    await command.commandRequest(
      `/settings update_shared_inbox ${teamId} ${JSON.stringify(data)}`
    );
  },
  async createGmailAlias() {
    store.commit("auth/updateAccessToken", store.getters["auth/accessToken"]);
    let response = await shared_inbox.createSharedInbox(
      "gmail-alias",
      JSON.stringify([store.state.data.sharedInbox.email])
    );
    let data = {
      name: store.getters["data/getSharedInbox"].name,
    };

    await this.updateSharedInbox(response[0].team_id, data);
  },
};
