<template>
  <LoopLeft>
    <div class="h-100 text-center intro-padding intro align-items-center">
      <div class="intro-heading position-relative mb-5">
        <div>Choose a new password</div>
        <SetNewPassword class="mt-3" />
      </div>
    </div>
  </LoopLeft>
</template>

<script>
import SetNewPassword from "@/components/forms/SetNewPassword";
import LoopLeft from "@/layout/LoopLeft";
export default {
  name: "set-new",
  components: { LoopLeft, SetNewPassword },
};
</script>
