<template>
  <b-toast
    id="toast-success"
    :toast-class="size"
    solid
    no-close-button
    toaster="b-toaster-bottom-left"
  >
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <img src="@/assets/img/toast/success.svg" alt="&#10003;" class="mr-3" />
        <slot></slot>
        <div style="margin-bottom: 2px">
          {{ text }}
        </div>
      </div>
      <div>
        <img
          src="@/assets/img/toast/close.svg"
          alt="&#10003;"
          style="margin-left: 50px"
          class="clickable"
          @click="$bvToast.hide('toast-success')"
        />
      </div>
    </div>
  </b-toast>
</template>

<script>
export default {
  name: "ToastSuccess",
  props: {
    text: {
      type: String,
      default: function () {
        return "";
      },
    },
    size: { type: String, default: () => "" },
  },
};
</script>

<style lang="scss" scoped></style>
