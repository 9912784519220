import { minLength, required, sameAs } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";
import { logging } from "@/api/logs";
import { mix_panel } from "@/services/common/mixpanel";
import { registration } from "@/api/int/registration";
import { mapState } from "vuex";
import { auth_constants } from "@/services/common/constants";

export default {
  mixins: [validation],
  data() {
    return {
      form: {
        password: "",
        repeatPassword: "",
      },
      response: "",
      loading: false,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(7),
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  computed: {
    ...mapState({
      email: (state) => state.auth.email,
      key: (state) => state.auth.key,
    }),
  },
  methods: {
    async submitForm() {
      this.loading = true;
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        logging.sendLog("FormSubmitted", 3);
        await mix_panel.onBasicEvent("FormSubmitted", "");
        await this.changePassword();
      } else {
        logging.sendLog("Form invalid", 3);
        await mix_panel.onBasicEvent("FormInvalid", "");
      }
      this.loading = false;
    },
    async changePassword() {
      let data = {
        identificator: this.email,
        key: this.key,
        newPassword: this.form.password,
        newPasswordConfirm: this.form.repeatPassword,
      };
      let response;
      try {
        response = await registration.changePassword(data);
        await this.processResponse(response.data);
      } catch (e) {
        logging.sendLog("FORM SUBMIT ERROR", 6);
      }
    },
    async processResponse(response) {
      if (response.authorizationFailed) {
        this.serverErrors["password"] = [auth_constants.INVALID_PASSWORD];
        this.$v.form.password.$touch();
      } else if (response.changed) {
        this.$router.push("/forgot-password/success");
      } else if (response.passwordPolicyFailed) {
        this.serverErrors["newPassword"] = [auth_constants.PASSWORD_POLICY];
        this.$v.form.newPassword.$touch();
      }
    },
  },
};
