<template>
  <LoopLeft class="h-100" :top-circle="false">
    <div class="h-100 text-center intro align-items-center downloading-wrapper">
      <div class="intro-heading position-relative">
        <div>
          Success!
          <br class="d-block d-md-none" />
          Your Loop app is downloading!
        </div>
      </div>
      <b-row class="justify-content-center">
        <b-col xl="8" lg="9" md="12">
          <div class="intro-subtitle mt-4">
            Once the download finishes, just install and open it to start
            getting your inbox under control.
          </div>
          <div class="intro-subtitle mt-md-5 mt-3 mb-3 mb-md-0">
            While you wait, book a quick tour of Loop key features to get
            started even quicker:
          </div>
        </b-col>
      </b-row>
      <!-- Calendly inline widget begin -->
      <div
        class="calendly-inline-widget"
        data-url="https://calendly.com/loop-email/personalized-loop-training?hide_gdpr_banner=1"
        style="min-width: 320px; height: 750px"
      ></div>
      <!-- Calendly inline widget end -->
    </div>
    <a ref="apple" :href="allSystems[1].downloadLink" download />
    <a ref="windows" :href="allSystems[0].downloadLink" download />
  </LoopLeft>
</template>

<script>
import LoopLeft from "@/layout/LoopLeft";
import { mix_panel } from "@/services/common/mixpanel";
import newDownload from "@/mixins/newDownload/newDownload";
export default {
  name: "index",
  components: { LoopLeft },
  mixins: [newDownload],
  created() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    async bookDemo() {
      await mix_panel.onBasicEvent("BookDemo", "");
      window.location = this.getCalendlyLink();
    },
    getCalendlyLink() {
      return "https://calendly.com/loop-email/personalized-loop-demo?month=2021-09";
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1669px) {
  .calendly-inline-widget {
    height: 1270px !important;
  }
}
@media (min-width: 1669px) {
  .calendly-inline-widget {
    height: 970px !important;
  }
}
</style>
