export const auth = {
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../../views/index"),
    },
    {
      path: "/login-new",
      name: "LoginNew",
      component: () => import("../../views/login/index"),
    },
    {
      path: "/forgot-password/send-email",
      name: "ForgotPassword",
      component: () => import("../../views/forgot-password/index"),
    },
    {
      path: "/forgot-password",
      name: "SetNewPassword",
      component: () => import("../../views/forgot-password/set-new"),
    },
    {
      path: "/auth/user-logout",
      name: "ForceLogout",
      component: () => import("../../views/auth/user-logout/index"),
    },
    {
      path: "/forgot-password/success",
      name: "ForgotPasswordSuccess",
      component: () => import("../../views/reset-password/success"),
    },
    {
      path: "/forgot-password/email-sent",
      name: "ForgotPasswordEmailSent",
      component: () => import("../../views/forgot-password/email-sent"),
    },
    {
      path: "/email-verification",
      name: "EmailVerification",
      component: () => import("../../views/email-verification/index"),
    },
    {
      path: "/email-verification/:url_purpose",
      name: "EmailVerificationVerify",
      components: {
        default: () => import("../../views/email-verification/verify"),
      },
    },
    {
      path: "/change-email/:step",
      name: "ChangeEmail",
      components: {
        default: () => import("../../views/change-email"),
      },
    },
  ],
};
