export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem("userData")) || "",
    intention: localStorage.getItem("intention") || "",
    download: JSON.parse(localStorage.getItem("download")) || false,
    // TODO remove
    verification: JSON.parse(localStorage.getItem("verification")),
    origin: getOriginFromLocalStore() || "",
    emailAuthType: localStorage.getItem("emailAuthType") || null,
    utmParams: JSON.parse(localStorage.getItem("utmParams")) || {},
  },
  getters: {
    user: (state) => state.user,
    intention: (state) => state.intention,
    verification: (state) => state.verification,
    origin: (state) => state.origin,
  },
  mutations: {
    setUser: (state, user) => {
      localStorage.user = JSON.stringify(user);
      state.user = user;
    },
    setSingleUtmParam: (state, [key, value]) => {
      state.utmParams[key] = value;
      localStorage.utmParams = JSON.stringify(state.utmParams);
    },
    setIntention: (state, intention) => {
      localStorage.intention = intention;
      state.intention = intention;
    },
    setDownload: (state, download) => {
      localStorage.download = JSON.stringify(download);
      state.download = download;
    },
    setOrigin: (state, origin) => {
      localStorage.origin = origin;
      state.origin = origin;
    },
    setEmailAuthType: (state, emailAuthType) => {
      localStorage.emailAuthType = emailAuthType;
      state.emailAuthType = emailAuthType;
    },
  },
};

function getOriginFromLocalStore() {
  let origin = localStorage.getItem("origin");

  if (!origin || origin === "undefined") {
    return '"web-app-prod"';
  }

  return origin;
}
