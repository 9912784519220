<template>
  <div class="background" :style="getStyle" />
</template>

<script>
export default {
  name: "RadialGradient",
  props: {
    color: { type: String, required: true },
    alpha: { type: String, default: "80" },
  },
  computed: {
    getStyle() {
      return `background: radial-gradient(${this.color}${this.alpha} 0.2%, transparent 60%)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  width: 300px;
  height: 300px;
}
</style>
