<template>
  <LoopLeft class="h-100">
    <div
      class="text-center d-flex justify-content-center align-items-center h-100"
    >
      <div v-if="isWebApp">
        <div class="main-title">Redirecting you to Loop...</div>
      </div>
      <div v-else>
        <b-spinner class="mt-5" variant="primary" v-if="loading" />
      </div>
    </div>
  </LoopLeft>
</template>

<script>
import LoopLeft from "@/layout/LoopLeft";
import { microsoft } from "@/services/oauth/microsoft";
import { google } from "@/services/oauth/google";
import { auth } from "@/api/auth";
import OriginEnum from "@/helpers/enums/OriginEnum";
import { mapMutations } from "vuex";
import { mix_panel } from "@/services/common/mixpanel";
import domains from "@/mixins/helpers/domains";

export default {
  name: "ProcessLogin",
  components: { LoopLeft },
  props: {
    strategy: { type: String, required: true },
  },
  mixins: [domains],
  data() {
    return {
      refreshToken: "",
      accessToken: "",
      userProfile: {},
      loading: true,
      img: "",
      isWebApp: false,
    };
  },
  async mounted() {
    if (this.$route.fullPath.includes(OriginEnum.WEBAPP_ALPHA)) {
      if (this.$route.fullPath.includes(OriginEnum.WEBAPP_LOOP_ALPHA)) {
        this.setOrigin(OriginEnum.WEBAPP_LOOP_ALPHA);
      } else {
        this.setOrigin(OriginEnum.WEBAPP_ALPHA);
      }
    } else if (this.$route.fullPath.includes(OriginEnum.WEBAPP_STAGE_BETA)) {
      this.setOrigin(OriginEnum.WEBAPP_STAGE_BETA);
    } else if (this.$route.fullPath.includes(OriginEnum.WEBAPP_LOOP_BETA)) {
      this.setOrigin(OriginEnum.WEBAPP_LOOP_BETA);
    } else if (this.$route.fullPath.includes(OriginEnum.WEBAPP_BETA)) {
      this.setOrigin(OriginEnum.WEBAPP_BETA);
    } else if (this.$route.fullPath.includes(OriginEnum.WEBAPP_PROD)) {
      if (
        this.$store.state.data.origin !== OriginEnum.MARKETING &&
        this.$store.state.data.origin !== OriginEnum.INVITE
      ) {
        this.setOrigin(OriginEnum.WEBAPP_PROD);
      }
    } else if (this.$route.fullPath.includes(OriginEnum.WEBAPP_LOCAL_HOST)) {
      this.setOrigin(OriginEnum.WEBAPP_LOCAL_HOST);
    } else if (this.$route.fullPath.includes(OriginEnum.MOBILE)) {
      this.setOrigin(OriginEnum.MOBILE);
    } else if (this.$route.fullPath.includes(OriginEnum.MOBILE_V2)) {
      this.setOrigin(OriginEnum.MOBILE_V2);
    } else {
      this.setOrigin(OriginEnum.DTA);
    }

    localStorage.oauthState = this.$route.query.state;

    if (this.$route.query.error) {
      localStorage.oauthError = this.$route.query.error;
      this.redirectToSuccess();
      return;
    }

    if (this.$store.state.data.origin.includes("web")) {
      this.isWebApp = true;
    }

    let proceed;
    try {
      if (this.strategy === "microsoft") {
        await mix_panel.onBasicEvent("MicrosoftLogin", "ProcessResponse");
        await microsoft.getRefreshTokenMicrosoft(this.$route.query.code);
        await this.processEmail();
        await mix_panel.onBasicEvent(
          "MicrosoftLogin",
          "ProcessResponseSucceed"
        );
      } else {
        await mix_panel.onBasicEvent("GoogleLogin", "ProcessResponse");
        await google.getRefreshTokenGoogle(this.$route.query.code);
        await this.processEmail();
        await mix_panel.onBasicEvent("GoogleLogin", "ProcessResponseSucceed");
      }
    } catch (e) {
      if (this.strategy === "microsoft") {
        await mix_panel.onBasicEvent("MicrosoftLogin", "LoadingSecondFailed");
      } else {
        await mix_panel.onBasicEvent("GoogleLogin", "LoadingSecondFailed");
      }
      this.$router.push(`/?from=${this.$store.state.data.origin}`);
    } finally {
      if (proceed) {
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapMutations("data", ["setOrigin"]),
    async createAccount() {
      await this.processAuthStrategy();

      this.redirectToSuccess();
    },
    async processAuthStrategy() {
      if (this.strategy === "microsoft") {
        await mix_panel.onBasicEvent("MicrosoftLogin", "RegisteringUser");
        await microsoft.registerMicrosoft();
      } else {
        await mix_panel.onBasicEvent("GoogleLogin", "RegisteringUser");
        await google.registerGoogle();
      }
    },
    async processEmail() {
      let response = await auth.getEmailType(this.$store.state.auth.email);
      if (response.data.alreadyRegistered) {
        localStorage.redirectAfterLogin = "/success?login=true";
        this.$store.commit("auth/updateIsRegistered", true);
        await this.processAuthStrategy();
      } else {
        let [domainType, properties] = this.getEmailDomainUrlParameter(
          this.$store.state.auth.email
        );
        mix_panel.setUserProperties(properties, async () => {
          localStorage.redirectAfterLogin = `/success?signup_complete=true&email_domain=${domainType}`;
          this.$store.commit("auth/updateIsRegistered", false);
          await this.processAuthStrategy();
        });
      }
    },
    redirectToSuccess() {
      if (localStorage.redirectAfterLogin) {
        this.$router.push(localStorage.redirectAfterLogin);
      } else {
        this.$router.push("/success");
      }
    },
  },
};
</script>
