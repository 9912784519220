<template>
  <div>
    <b-container
      fluid
      class="h-100 d-flex flex-column text-lg-left text-center pl-5 pr-5 pl-md-0 pr-md-0 wrapper"
    >
      <b-row
        class="justify-content-center align-content-center"
        style="height: 80px"
      >
        <b-col xl="9" lg="11" md="12" class="mt-5">
          <img src="@/assets/img/logo-blue.svg" alt="logo" height="46px" />
        </b-col>
      </b-row>
      <b-row
        class="justify-content-center align-content-center mt-lg-auto mt-md-5 mt-3"
      >
        <b-col xl="9" lg="11" md="12">
          <RadialGradient
            class="d-md-block d-lg-none"
            style="position: absolute; bottom: -250px; right: 0"
            alpha="50"
            color="#5640B2"
          />
          <RadialGradient
            class="d-md-block d-lg-none"
            style="position: absolute; top: -120px; left: -50px"
            alpha="40"
            color="#F5A632"
          />
          <slot name="content" />
        </b-col>
      </b-row>
      <b-row
        class="mt-auto justify-content-center align-content-center"
        style="height: 80px"
      >
        <b-col xl="9" lg="11" md="12">
          <slot name="footer" />
        </b-col>
      </b-row>
    </b-container>
    <div class="background-radial" />
  </div>
</template>

<script>
import RadialGradient from "@/components/nonFunctional/RadialGradient";
export default {
  name: "Main",
  components: { RadialGradient },
};
</script>

<style lang="scss">
.wrapper {
  min-height: 100vh;
}

.background-radial {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -2;
  background: radial-gradient(100% 250% at left, #f7f7f9 60%, #efeef2 60.05%);
}
.navbar {
  padding-left: 0 !important;
}
</style>
