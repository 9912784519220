import api from "@/client/client";
import { mix_panel } from "@/services/common/mixpanel";
import { logging } from "@/api/logs";
import { registration } from "@/api/int/registration";

const route = "/api/v1/auth/verify";

export const verification = {
  async verify(emailAddress, verificationKey, purpose) {
    logging.sendLog("Verifying your email", 3);
    await mix_panel.onBasicEvent("VerifyingEmail", "");
    let encodedEmail = encodeURIComponent(emailAddress);
    let finalRoute = `${route}?emailAddress=${encodedEmail}&verificationKey=${verificationKey}`;
    if (purpose && purpose.length > 0) {
      finalRoute += `&purpose=${purpose}`;
    }
    return api.post(finalRoute);
  },
  async resendEmail(email, purpose) {
    logging.sendLog("Verification Email requested", 3);
    await mix_panel.onBasicEvent("VerificationEmailRequested", purpose);

    let response;
    switch (purpose) {
      case "activate":
        response = await this.sendPurposeEmail(email, purpose);
        break;
      case "verify":
        response = await this.sendPurposeEmail(email, purpose);
        break;
      case "reset-password":
        response = await this.sendForgotEmail(email);
        break;
    }
    return response;
  },
  async sendForgotEmail(email) {
    let data = {
      email: email,
    };

    let response;
    try {
      response = await registration.forgotPassword(data);
    } catch (e) {
      logging.sendLog("Error sending forgot email", 6);
    }
    return response;
  },
  async sendPurposeEmail(email, purpose) {
    let data = {
      emailAddress: email,
      purpose: purpose,
    };

    let response;
    try {
      response = await registration.sendVerifyEmail(data);
    } catch (e) {
      logging.sendLog(`Error sending ${purpose} email`, 6);
    }
    return response;
  },
};
