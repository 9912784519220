<template>
  <div>
    <div class="subtitle mt-4">
      Please enter your current credentials to proceed
    </div>
    <LoginForm class="mt-3" button_text="Next" @success="$emit('next')" />
  </div>
</template>

<script>
import LoginForm from "@/components/forms/loopLogin/LoginForm";
export default {
  name: "Step1",
  components: { LoginForm },
};
</script>
