import { microsoft, ScopeMicrosoft } from "@/services/oauth/microsoft";
import { google, ScopeGoogle } from "@/services/oauth/google";
import OriginEnum from "@/helpers/enums/OriginEnum";
import { store } from "@/store";

export const oauth = {
  loginWithMicrosoft(email, scope = ScopeMicrosoft.DEFAULT) {
    microsoft.loginWithMicrosoft(email, scope);
  },
  loginWithGoogle(email, scope = ScopeGoogle.DEFAULT) {
    google.loginWithGoogle(email, scope);
  },
  async processMicrosoft(code) {
    await microsoft.getRefreshTokenMicrosoft(code);
  },
  async processGoogle(code) {
    await google.getRefreshTokenGoogle(code);
  },
  getRedirectUrl(strategy) {
    console.log(process.env.VUE_APP_ENV);
    switch (store.state.data.origin) {
      case OriginEnum.WEBAPP_LOCAL_HOST:
        return `/${OriginEnum.WEBAPP_LOCAL_HOST}-loading-second-${strategy}`;

      case OriginEnum.WEBAPP_ALPHA:
        return `/${OriginEnum.WEBAPP_ALPHA}-loading-second-${strategy}`;

      case OriginEnum.WEBAPP_BETA:
        return `/${OriginEnum.WEBAPP_BETA}-loading-second-${strategy}`;

      case OriginEnum.WEBAPP_PROD:
      case OriginEnum.INVITE:
      case OriginEnum.MARKETING:
        return `/${OriginEnum.WEBAPP_PROD}-loading-second-${strategy}`;

      case OriginEnum.MOBILE:
        return `/mobile-loading-second-${strategy}`;

      case OriginEnum.MOBILE_V2:
        return `/mobile-v2-loading-second-${strategy}`;

      case OriginEnum.WEBAPP_STAGE_BETA:
        return `/${OriginEnum.WEBAPP_STAGE_BETA}-loading-second-${strategy}`;

      case OriginEnum.WEBAPP_LOOP_ALPHA:
        return `/${OriginEnum.WEBAPP_LOOP_ALPHA}-loading-second-${strategy}`;
      case OriginEnum.WEBAPP_LOOP_BETA:
        return `/${OriginEnum.WEBAPP_LOOP_BETA}-loading-second-${strategy}`;

      default:
        return `/loading-second-${strategy}`;
    }
  },
};

export function isMicrosoftSharedInboxSetup() {
  let oauthState = localStorage.oauthState;
  return oauthState?.includes("microsoft-extended");
}

export function isFullScopeSetup() {
  let oauthState = localStorage.oauthState;

  return (
    oauthState?.includes("personal-inbox-setup") ||
    oauthState?.includes("shared-inbox-setup")
  );
}
