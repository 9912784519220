import intClient from "@/client/intClient";

const route = "/registration";

let headers = {};
headers["Content-Type"] = "application/json";
headers["Accept"] = "application/json";

export const registration = {
  createAccount(data) {
    return intClient.post(route, data, { headers });
  },
  forgotPassword(data) {
    return intClient.post(`${route}/forgot-password`, data, { headers });
  },
  changePassword(data) {
    return intClient.post(`${route}/change-password`, data, { headers });
  },
  sendVerifyEmail(data) {
    return intClient.post(`${route}/send-verify-email`, data, { headers });
  },
};
