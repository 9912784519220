import api from "@/client/client";

const route = "/api/v1/command";
let headers = {};
headers["Content-Type"] = "application/json";
headers["Accept"] = "application/json";

export const command = {
  commandRequest(command, additionalHeaders = {}) {
    const data = {
      request: command,
      $type: "RawCommand",
    };
    for (const [key, value] of Object.entries(additionalHeaders)) {
      headers[key] = value;
    }
    return api.post(`${route}`, data, { headers });
  },
};
