import api from "@/client/client";
import { queryBuilder } from "@/api/common/queryBuilder";
import { auth } from "@/api/auth";
import { store } from "@/store";
import { common } from "@/services/common/common";
import { logging } from "@/api/logs";
import { encryption } from "@/services/common/encryption";
import router from "@/router";
import { settings } from "@/api/settings";
import { oauth } from "@/services/oauth";

let strategy = "google";

export const ScopeGoogle = {
  DEFAULT:
    "https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
  LIMITED:
    "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
};

export const google = {
  loginWithGoogle(email, scope) {
    let state = encryption.encrypt(`LOOP_EMAIL_STATE:${new Date().getTime()}`);
    localStorage.state = state;
    const args = {
      scope: scope,
      response_type: "code",
      redirect_uri:
        process.env.VUE_APP_PAGE_LOCATION_URL + oauth.getRedirectUrl("google"),
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      access_type: "offline",
      prompt: "consent",
      force: true,
      state: state,
    };
    if (JSON.parse(store.state.auth.isSharedInbox)) {
      args["login_hint"] = store.state.data.sharedInbox.email;
    } else {
      if (email) {
        args["login_hint"] = email;
      }
    }
    window.location =
      "https://accounts.google.com/o/oauth2/v2/auth" +
      queryBuilder.buildQuery(args);
  },
  async getRefreshTokenGoogle(code) {
    try {
      const args = {
        code: code,
        redirect_uri:
          process.env.VUE_APP_PAGE_LOCATION_URL +
          oauth.getRedirectUrl("google"),
        state: localStorage.state
          ? localStorage.state
          : encryption.encrypt(`LOOP_EMAIL_STATE:${new Date().getTime()}`),
        responseForm: "credentials",
      };
      const response = await api.get(
        `/api/v1.4/token/google` + queryBuilder.buildQuery(args)
      );
      const final = common.parseTokenFromHtml(response.data);
      store.commit("auth/updateRefreshToken", final.RefreshToken);
      store.commit("auth/updateAccessToken", final.AccessToken);
      store.commit("auth/updateEmail", final.Email);
    } catch (e) {
      logging.sendLog("getRefreshTokenGoogle() error", 6);
      router.push(`/?from=${this.$store.state.data.origin}`);
    }
  },
  async registerGoogle() {
    const data = {
      email: store.state.auth.email,
      accessToken: store.state.auth.accessToken,
      $type: "AuthGoogle",
    };

    try {
      const response = await auth.registerGoogle(data);
      store.commit("auth/updateAccessToken", response.data.token.accessToken);
      // await store.dispatch("auth/getUser");
      await settings.settingsSync(strategy);
    } catch (e) {
      logging.sendLog("registerGoogle() error", 6);
      router.push(`/?from=${this.$store.state.data.origin}`);
    } finally {
      localStorage.removeItem("limitedScope");
    }
  },
  async getProfile() {
    const data = {
      email: store.state.auth.email,
      accessToken: store.state.auth.accessToken,
      $type: "AuthGoogle",
    };
    try {
      const response = await auth.gmailProfile(data);
      return response.data;
    } catch (e) {
      logging.sendLog("getGoogleProfile() error", 6);
      router.push(`/?from=${this.$store.state.data.origin}`);
    }
  },
};
