import { logging } from "@/api/logs";
import { mix_panel } from "@/services/common/mixpanel";
import { oauth } from "@/services/oauth";
import { ScopeGoogle } from "@/services/oauth/google";
import { ScopeMicrosoft } from "@/services/oauth/microsoft";
import { store } from "@/store";

export default {
  methods: {
    async googleLogin(email) {
      logging.sendLog(`GoogleLogin ${store.state.data.origin}`, 3);
      if (email !== undefined && email.length !== 0) {
        await mix_panel.onBasicEvent("GoogleLogin", "EnterClicked");
      } else {
        await mix_panel.onBasicEvent("GoogleLogin", "ButtonClicked");
      }
      localStorage.redirectAfterLogin = "/success";
      localStorage.limitedScope = "true";
      this.$store.commit("auth/setStrategy", "google");
      oauth.loginWithGoogle(email, ScopeGoogle.LIMITED);
    },
    async microsoftLogin(email) {
      logging.sendLog(`MicrosoftLogin ${store.state.data.origin}`, 3);
      if (email !== undefined && email.length !== 0) {
        await mix_panel.onBasicEvent("MicrosoftLogin", "EnterClicked");
      } else {
        await mix_panel.onBasicEvent("MicrosoftLogin", "ButtonClicked");
      }
      localStorage.redirectAfterLogin = "/success";
      localStorage.limitedScope = "true";
      this.$store.commit("auth/setStrategy", "microsoft");
      oauth.loginWithMicrosoft(email, ScopeMicrosoft.LIMITED);
    },
  },
};
