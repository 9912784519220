<template>
  <Signup>
    <template #content>
      <div class="registration-wrapper">
        <div class="marketing-col">
          <Invite />
        </div>
        <div>
          <Logo class="reg-logo logo" />
          <RegistrationCard :is-invite="true" />
        </div>
      </div>
    </template>
  </Signup>
</template>

<script>
import Logo from "@/components/Logo";
import Signup from "../layout/Signup";
import RegistrationCard from "../components/newDesign/RegistrationCard";
import Invite from "../components/invite/Invite";
import mobileRedirect from "../mixins/helpers/mobileRedirect";
export default {
  name: "index",
  components: { Invite, RegistrationCard, Signup, Logo },
  mixins: [mobileRedirect],
};
</script>

<style lang="scss" scoped>
.marketing-col {
  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
