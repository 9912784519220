<template>
  <div class="empty-circle" />
</template>

<script>
export default {
  name: "EmptyCircle",
};
</script>

<style lang="scss" scoped>
.empty-circle {
  width: 99.19px;
  height: 99.19px;

  border: 2.32203px solid #f5a632;
  border-radius: 50%;
  box-sizing: border-box;
}
</style>
