<template>
  <b-toast
    :id="id"
    toast-class="toast-error"
    variant="danger"
    solid
    no-close-button
    toaster="b-toaster-bottom-left"
  >
    <div class="d-flex align-items-center">
      <img
        src="@/assets/img/input/exclamation-mark.svg"
        alt="&#10003;"
        class="mr-3"
      />
      <div style="margin-bottom: 2px; width: 100%">
        {{ text }}
        <slot></slot>
      </div>
      <img
        src="@/assets/img/toast/close.svg"
        alt="&#10003;"
        style="position: relative; right: 0"
        class="clickable"
        @click="$bvToast.hide(id)"
      />
    </div>
  </b-toast>
</template>

<script>
export default {
  name: "ToastError",
  props: {
    text: {
      type: String,
      default: function () {
        return "";
      },
    },
    id: {
      type: String,
      default: function () {
        return "toast-error";
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
