import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "@/store";
import { logging } from "@/api/logs";
import { auth } from "@/router/routes/auth";
import { mix_panel } from "@/services/common/mixpanel";

Vue.use(VueRouter);

// const ifAuthenticated = (to, from, next) => {
//   if (store.getters["auth/isAuthenticated"]) {
//     next();
//     return;
//   }
//   next("/");
// };

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

function getAdditionalProperties(to) {
  if (to.query.from) {
    store.commit("data/setOrigin", to.query.from);
  }
  let origin = store.state.data.origin;

  let additionalProperties = {
    from: origin,
    origin: origin,
    email: store.state.auth.email,
  };
  if (to.query.email) {
    additionalProperties["email"] = to.query.email;
  }
  if (to.query.invited_by) {
    additionalProperties["invitedByName"] = to.query.invited_by;
  }
  if (to.query.invited_by_email) {
    additionalProperties["invitedBy"] = to.query.invited_by_email;
  }
  if (to.query.intention) {
    additionalProperties["intention"] = to.query.intention;
  }

  return additionalProperties;
}

const loginRoutes = [
  "/loading-second",
  "/web-app-local-host-loading-second",
  "/web-app-alpha-loading-second",
  "/web-app-beta-loading-second",
  "/web-app-prod-loading-second",
  "/web-app-beta-stage-loading-second",
  "/web-app-beta-loop-loading-second",
  "/web-app-alpha-loop-loading-second",
  "/mobile-loading-second",
  "/mobile-v2-loading-second",
];
const loginRoutesMicrosoft = loginRoutes.map((path) => {
  return {
    path: path + "-microsoft",
    name: path + "-microsoft",
    component: () => import("../views/loading-second/microsoft.vue"),
  };
});
const loginRoutesGoogle = loginRoutes.map((path) => {
  return {
    path: path + "-google",
    name: path + "-google",
    component: () => import("../views/loading-second/google.vue"),
  };
});

const routes = [
  ...loginRoutesGoogle,
  ...loginRoutesMicrosoft,
  {
    path: "/success",
    name: "SuccessPage",
    components: {
      default: () => import("../views/success/index"),
    },
  },
  {
    path: "/download",
    name: "Download",
    components: {
      default: () => import("../views/download/index.vue"),
    },
  },
  {
    path: "/downloading",
    name: "Downloading",
    components: {
      default: () => import("../views/downloading/index"),
    },
  },
  {
    path: "/invite",
    name: "Invite",
    component: () => import("../views/Invite.vue"),
  },
  ...auth.routes,
  {
    path: "*",
    name: "PageNotFound",
    components: {
      default: () => import("../components/errors/PageNotFound"),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let name = to.name;

  await mix_panel.onBasicEvent(
    "PageEntered",
    name,
    getAdditionalProperties(to)
  );
  logging.sendLog(`Redirecting from: ${from.path}, to: ${to.path}`, 3, [
    { name: "fromPage", value: from.name },
    { name: "toPage", value: to.name },
    { name: "fromPageUrl", value: from.path },
    { name: "toPageUrl", value: to.path },
    { name: "toPageParams", value: JSON.stringify(to.query) },
  ]);
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    store.commit("log/setReferralPage", from.name);
    next({ path: to.path, query: from.query });
  } else {
    store.commit("log/setReferralPage", from.name);
    next();
  }
});

export default router;
