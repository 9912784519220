import { common } from "@/services/common/common";
import { mix_panel } from "@/services/common/mixpanel";
import validation from "@/mixins/validation";
import { email, required } from "vuelidate/lib/validators";

export default {
  mixins: [validation],
  data() {
    return {
      allSystems: common.OSes,
      email: "",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    getNth(d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    },
    async download(os) {
      await mix_panel.onBasicEvent("DownloadClicked", os);
      let vm = this;
      if (os === "Mac") {
        this.$refs.apple.click();
        setTimeout(function () {
          vm.$router.push(`/downloading?os=${os}`);
        }, 500);
      } else if (os === "Windows") {
        this.$refs.windows.click();
        setTimeout(function () {
          vm.$router.push(`/downloading?os=${os}`);
        }, 500);
      } else if (os === "MacM1") {
        this.$refs.macM1.click();
        setTimeout(function () {
          vm.$router.push(`/downloading?os=${os}`);
        }, 500);
      }
    },
    async whatsNew() {
      await mix_panel.onBasicEvent("WhatsNewClicked", "true");
    },
    async applyForBeta() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.$bvToast.show("toast-success");
        await mix_panel.identify(this.email);
        await mix_panel.onBasicEvent("ApplyForBeta", "true", {
          email: this.email,
        });
        this.$router.push("/thank-you");
      }
    },
    async openModal() {
      await mix_panel.onBasicEvent("ModalOpened", "true");
      this.$bvModal.show("emailInput");
    },
    async closeModal() {
      await mix_panel.onBasicEvent("ModalClosed", "true");
      this.$v.$reset();
      this.$bvModal.hide("emailInput");
    },
  },
};
