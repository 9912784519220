import { auth } from "@/api/auth";

export const password_login = {
  async login(email, password) {
    let data = {
      $type: "AuthPassword",
      identificator: email,
      password: password,
    };
    return auth.loopLogin(data);
  },
};
