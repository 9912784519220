import OriginEnum from "../../helpers/enums/OriginEnum";
import DeeplinkEnum from "../../helpers/enums/DeeplinkEnum";

export default {
  methods: {
    redirectToWeb(code, authType, oauthEmail, oauthError) {
      let route = `/deeplink/${DeeplinkEnum.CODE_LOGIN}?code=${code}&auth-type=${authType}`;
      route += getExtraOathParams(oauthEmail, oauthError);

      switch (this.$store.state.data.origin) {
        case OriginEnum.WEBAPP_ALPHA:
        case OriginEnum.WEBAPP_LOOP_ALPHA:
          window.location = process.env.VUE_APP_WEB_ALPHA_URL + route;
          break;
        case OriginEnum.WEBAPP_BETA:
        case OriginEnum.WEBAPP_LOOP_BETA:
          window.location = process.env.VUE_APP_WEB_BETA_URL + route;
          break;
        case OriginEnum.WEBAPP_LOCAL_HOST:
          window.location = `http://localhost:4200` + route;
          break;
        case OriginEnum.WEBAPP_PROD:
        case OriginEnum.MARKETING:
        case OriginEnum.WEBAPP_STAGE_BETA:
        default:
          window.location = process.env.VUE_APP_WEB_URL + route;
          break;
      }
    },
  },
};

export function getExtraOathParams(oauthEmail, oauthError) {
  let extraParams = "";

  if (localStorage.oauthState) {
    extraParams += `&state=${encodeURIComponent(localStorage.oauthState)}`;
  }
  if (localStorage.refreshToken) {
    extraParams += `&oauthRefreshToken=${encodeURIComponent(
      localStorage.refreshToken
    )}`;
  }
  if (oauthError) {
    extraParams += "&permission-denied=true";
  }
  if (oauthEmail) {
    extraParams += `&oauthEmail=${encodeURIComponent(oauthEmail)}`;
  }

  return extraParams;
}

export function isOauthForSIorEnableSync() {
  return isOauthForSI() || isOauthForEnableSync();
}

export function isOauthForSI() {
  return (
    localStorage.oauthState?.includes("shared-inbox-setup") &&
    !localStorage.oauthState?.includes("personal_shared")
  );
}

export function isOauthForEnableSync() {
  return localStorage.oauthState?.includes("personal-inbox-setup");
}
