<template>
  <div class="circle" :style="'background-color:' + color" />
</template>

<script>
export default {
  name: "CircleElement",
  props: {
    color: { type: String, default: () => "#EECB48" },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  width: 100px;
  height: 100px;
  z-index: 2;
  border-radius: 50%;
}
</style>
