import api from "@/client/client";
import router from "@/router";
import { store } from "@/store";
import { logging } from "@/api/logs";

const routePath = `/api/v1/settings/`;

export const settings = {
  async settingsSync(strategy, loginData = null, isNative = false) {
    try {
      const response = await api.get(`${routePath}sync`);
      logging.sendLog(
        `User logged in using ${strategy}, GET sync settings.`,
        3
      );
      if (
        (response.data.isEnabled === false ||
          response.data.isEnabled === "false") &&
        !store.state.auth.alreadyRegistered
      ) {
        if (strategy === "google") {
          await this.settingsGoogle(true);
        } else if (strategy === "microsoft") {
          await this.settingsMicrosoft(true);
        } else if (strategy === "exchange") {
          await this.settingsExchange(true, loginData);
        } else if (strategy === "imap") {
          await this.settingsImap(true, loginData, isNative);
        }
      }
    } catch (e) {
      logging.sendLog(
        `User registered using ${strategy}, POST sync settings.`,
        3
      );
      if (strategy === "google") {
        await this.settingsGoogle(false);
      } else if (strategy === "microsoft") {
        await this.settingsMicrosoft(false);
      } else if (strategy === "exchange") {
        await this.settingsExchange(false, loginData);
      } else if (strategy === "imap") {
        await this.settingsImap(false, loginData, isNative);
      }
    }
    if (localStorage.redirectAfterLogin) {
      router.push(localStorage.redirectAfterLogin);
    }
  },
  async settingsMicrosoft(usePut) {
    try {
      const data = {
        refreshToken: store.getters["auth/refreshToken"],
        $type: "SyncSettingsMicrosoft",
        isEnabled: false,
      };

      if (usePut) {
        await api.put(`${routePath}microsoft`, data);
      } else {
        await api.post(`${routePath}microsoft`, data);
      }
    } catch (e) {
      logging.sendLog(`settingsMicrosoft(${usePut}) error`, 6);
    }
  },
  async settingsExchange(usePut, loginData) {
    const data = {
      email: loginData.email,
      secret: loginData.password,
      $type: "SyncSettingsExchange",
      isEnabled: false,
    };
    try {
      if (usePut) {
        return api.put(`${routePath}exchange`, data);
      } else {
        await api.post(`${routePath}exchange`, data);
      }
    } catch (e) {
      logging.sendLog(`settingsExchange(${usePut}) error`, 6);
    }
  },
  async settingsImap(usePut, loginData, isNative) {
    try {
      if (isNative) {
        if (usePut) {
          await api.put(`${routePath}imap`, loginData);
        } else {
          await api.post(`${routePath}imap`, loginData);
        }
      } else {
        if (usePut) {
          await api.put(`${routePath}remoteimap`, loginData);
        } else {
          await api.post(`${routePath}remoteimap`, loginData);
        }
      }
    } catch (e) {
      logging.sendLog(`settingsImap(${usePut}) error`, 6);
    }
  },
  async settingsGoogle(usePut) {
    try {
      const data = {
        refreshToken: store.getters["auth/refreshToken"],
        $type: "SyncSettingsGmail",
        isEnabled: false,
      };

      if (usePut) {
        return api.put(`${routePath}gmail`, data);
      } else {
        return api.post(`${routePath}gmail`, data);
      }
    } catch (e) {
      logging.sendLog(`settingsGoogle(${usePut}) error`, 6);
    }
  },
};
