export const queryBuilder = {
  buildQuery(args) {
    let queryList =
      Object.keys(args).map(function (param) {
        return param + "=" + args[param];
      }) || [];

    return queryList.length > 0 ? "?" + queryList.join("&") : "";
  },
};
