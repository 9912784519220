<template>
  <BaseCard>
    <template v-slot:header>
      A
      <span style="color: #5640b2">game-changer</span>
      for teams
    </template>
    <template v-slot:content>
      "Forwarding and switching between communication apps is a thing of the
      past for us and the clutter has been greatly reduced.
      <br />
      We love it!"
    </template>
    <template v-slot:footer>
      <div class="weight-bold">Andrej - CEO</div>
    </template>
    <template v-slot:absolute-components>
      <RadialGradient
        style="position: absolute; top: -130px; right: -130px; z-index: -1"
        color="#5640B2"
      />
      <img
        src="@/assets/img/andrej.png"
        rounded="circle"
        alt="Circle image"
        style="
          position: absolute;
          right: -40px;
          bottom: -40px;
          border-radius: 20px;
          width: 100px;
        "
      />
      <slot name="additional-components" />
    </template>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/cards/BaseCard";
import RadialGradient from "@/components/nonFunctional/RadialGradient";
export default {
  name: "GameChangerCard",
  components: { RadialGradient, BaseCard },
};
</script>
