export const constants = {
  INTENTION: {
    SHAREDINBOX: "shared-inbox",
    TEAM: "team",
    PERSONAL: "personal",
    DEFAULT: "default",
  },
  REQUIRED_FIELD: "Please enter your",
  INVALID_EMAIL: "Please enter a valid email address.",
  RECOMMEND_WORK_EMAIL:
    "We recommend using your work email so you can easily invite your team and test Loop with them.",
  SERVER_ERROR: "ServerError",
  LOGIN_ERROR: "LoginError",
  requiredField(field) {
    return `${this.REQUIRED_FIELD} ${field}.`;
  },
};
export const auth_constants = {
  INVALID_PASSWORD: "Incorrect password.",
  PASSWORD_POLICY: "Password must have at least 7 characters.",
};
export const email_sender_constants = {
  NOT_SENT: "Email was not sent.",
};
export const tracking_constants = {
  EMAIL: "$email",
  INTENTION: "onBoardingIntention",
  DOMAIN_TYPE: "DomainType",
  PERSONAL_DOMAIN: "Personal",
  BUSINESS_DOMAIN: "Business",
};
export const defaultUserAvatarImage =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' viewBox='0 0 96 96'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cpath fill='%23FFF' fill-opacity='0' d='M0 0h96v96H0z'/%3E%3Ccircle cx='48' cy='48' r='48' fill='%2355457f'/%3E%3C/g%3E%3Cg fill='%23FFF'%3E%3Cpath fill-opacity='.01' d='M18 18h60v60H18z'/%3E%3Cpath d='M33.003 66h29.994c-.06-4.422-1.41-8.664-3.81-11.973l-3.27 1.254c-7.578 3.651-15.42.369-16.143.051l-3.015-1.281c-2.358 3.327-3.693 7.551-3.756 11.949zm35.55 6H27.429l-.273-2.697A29.612 29.612 0 0 1 27 66.321c0-5.76 1.746-11.355 4.914-15.753 1.653-2.298 4.614-3.138 7.191-2.04l3.093 1.314c.171.075 5.955 2.52 11.328-.06l3.489-1.374c2.514-.996 5.409-.129 7.026 2.094C67.239 54.909 69 60.528 69 66.321c0 1.017-.051 2.016-.159 3.003L68.553 72z'/%3E%3Cpath d='M48 30c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6m0 18c-6.618 0-12-5.382-12-12s5.382-12 12-12 12 5.382 12 12-5.382 12-12 12'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A";
