import base from "@/mixins/loopLogin/base";
import { email, minLength, required } from "vuelidate/lib/validators";
import { registration } from "@/api/int/registration";
import { mix_panel } from "@/services/common/mixpanel";
import { logging } from "@/api/logs";
import { constants, tracking_constants } from "@/services/common/constants";
import { auth } from "@/api/auth";
import { oauth } from "@/services/oauth";
import { features } from "@/services/common/features";
import { store } from "@/store";
import { mapMutations, mapState } from "vuex";
import oauthLogin from "@/mixins/oauth/oauthLogin";
import OriginEnum from "@/helpers/enums/OriginEnum";
import { common } from "../../services/common/common";

export default {
  extends: base,
  mixins: [oauthLogin],
  data() {
    return {
      form: {
        ...this.form,
        // intention: "",
      },
      loading: false,
      firstTry: true,
      options: [
        {
          text: "1",
          value: "1",
          buttonVariant: "primary",
        },
        {
          text: "2",
          value: "2",
          buttonVariant: "danger",
        },
      ],
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(7),
      },
      firstName: {
        required,
      },
      lastName: {},
    },
  },
  computed: {
    ...mapState({
      intention: (state) => state.data.intention,
    }),
  },
  mounted() {
    this.form.email = this.$route.query.email;
  },
  methods: {
    ...mapMutations("auth", ["updateFirstEmail", "updateEmail"]),
    ...mapMutations("data", ["setIntention"]),
    async register() {
      this.loading = true;
      this.$v.form.email.$touch();
      if (!this.$v.form.email.$anyError) {
        let response = await auth.getEmailType(this.form.email);
        this.updateEmail(this.form.email);
        if (response.data.emailServiceType === "Google") {
          await this.googleLogin(this.form.email);
        } else if (response.data.emailServiceType === "Microsoft") {
          await this.microsoftLogin(this.form.email);
        } else {
          if (response.data.alreadyRegistered) {
            this.serverErrors = {
              email: [common.IDENTIFICATOR_ALREADY_IN_USE],
            };
            this.$v.form.email.$touch();
          } else {
            this.setFirstAndLastName();
            if (!this.firstTry) {
              this.updateEmail(this.form.email);
              this.$v.form.password.$touch();
              await mix_panel.onBasicEvent("LoopLogin", "PasswordEntered");
              if (
                !this.$v.form.password.$anyError &&
                !this.$v.form.firstName.$anyError
              ) {
                await mix_panel.onBasicEvent("LoopLogin", "SubmitClicked");
                await this.processForm();
              } else {
                await mix_panel.onBasicEvent("PasswordIncomplete", "");
              }
            } else {
              await mix_panel.onBasicEvent("LoopLogin", "EmailEntered");
              this.firstTry = false;
            }
          }
        }
      } else {
        await mix_panel.onBasicEvent("LoopLogin", "InvalidEmailEntered");
      }
      this.loading = false;
    },
    setFirstAndLastName() {
      if (!this.form.firstName) {
        let prefix = this.form.email.split("@")[0];
        let [first, last] = prefix.split(".");
        this.form.firstName = common.capitalizeFirstLetter(first);
        if (last) {
          this.form.lastName = common.capitalizeFirstLetter(last);
        }
      }
    },
    async processForm() {
      this.updateEmail(this.form.email);
      let data = {
        identificator: this.form.email,
        password: this.form.password,
        intent: store.state.data.intention,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
      };

      // if (this.form.intention === "personal") {
      //   await this.personalProcessing();
      //   return;
      // }

      let response;
      try {
        this.$store.commit("auth/setStrategy", "loopLogin");
        response = await registration.createAccount(data);
      } catch (e) {
        logging.sendLog(
          `FORM SUBMIT ERROR ${JSON.stringify(e.response.data)}`,
          6
        );
        await mix_panel.onBasicEvent("UserCreatedFailed", "");
      }
      await this.processResponse(response);
      this.loading = false;
    },
    async processResponse(response) {
      if (response.data.created) {
        logging.sendLog("User created", 3);
        await mix_panel.onBasicEvent("UserCreated", "LoopLogin");
        let properties = {};
        properties[tracking_constants.EMAIL] = this.form.email;
        mix_panel.setUserProperties(properties);

        if (features.verification && store.state.data.verification) {
          this.setIntention(null);
          await this.$router.push(
            `/email-verification?email=${this.form.email}`
          );
        } else {
          // Call Email helper if MOBILE -> they are using different logic than DTA
          if (
            this.$store.state.data.origin === OriginEnum.MOBILE ||
            this.$store.state.data.origin === OriginEnum.MOBILE_V2
          ) {
            await auth.getEmailType(this.form.email);
          }

          switch (this.$store.state.data.origin) {
            case OriginEnum.WEBAPP_PROD:
            case OriginEnum.WEBAPP_BETA:
            case OriginEnum.WEBAPP_ALPHA:
            case OriginEnum.WEBAPP_LOCAL_HOST:
            case OriginEnum.INVITE:
            case OriginEnum.MARKETING:
            case OriginEnum.DTA:
              await this.$router.push(
                `/email-verification?email=${this.form.email}`
              );
              break;
            default:
              await this.$router.push("/success");
              break;
          }
        }
      }
      if (response.data.passwordPolicyFailed) {
        logging.sendLog("Password policy failed -> SERVER!!!", 6);
        await mix_panel.onBasicEvent(
          constants.SERVER_ERROR,
          "PasswordPolicyViolated"
        );
        this.serverErrors = {
          password: [common.PASSWORD_POLICY_ERROR],
        };
        this.$v.form.password.$touch();
      }
      if (response.data.allreadyExists) {
        logging.sendLog("User with that identificator already exists", 3);
        await mix_panel.onBasicEvent("ServerError", "UserExists");
        this.serverErrors = {
          email: [common.IDENTIFICATOR_ALREADY_IN_USE],
        };
        this.$v.form.email.$touch();
      }
      if (
        "allreadyExists" in response.data &&
        !response.data.allreadyExists &&
        !response.data.passwordPolicyFailed &&
        !response.data.created
      ) {
        logging.sendLog(
          "User with that identificator already have syncing account",
          3
        );
        await mix_panel.onBasicEvent("ServerError", "UserHaveSyncingAccount");
        this.serverErrors = {
          email: [common.IDENTIFICATOR_ALREADY_IN_USE],
        };
        this.$v.form.email.$touch();
      } else if (!("allreadyExists" in response.data)) {
        logging.sendLog(`Error! ${JSON.stringify(response)}`, 6);
        this.serverErrors = {
          email: [common.NETWORK_PROBLEM],
        };
      }
    },
    async personalProcessing() {
      localStorage.redirectAfterLogin = "/success";
      let response = await auth.getEmailType(this.form.email);
      let emailType = response.data.emailServiceType;
      if (emailType === "Imap" || emailType === "RemoteImap") {
        this.$router.push("/personal/imap");
      } else if (emailType === "Exchange") {
        this.$router.push("/personal/exchange");
      } else if (emailType === "Microsoft") {
        localStorage.strategy = "microsoft";
        oauth.loginWithMicrosoft(this.form.email);
      } else if (emailType === "Google") {
        localStorage.strategy = "google";
        oauth.loginWithGoogle(this.form.email);
      }
    },
  },
};
