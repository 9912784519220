<template>
  <div>
    <div class="subtitle mt-4">Please choose your new email and password</div>
    <ChangeEmail />
  </div>
</template>

<script>
import ChangeEmail from "@/components/forms/ChangeEmail";
export default {
  name: "Step2",
  components: { ChangeEmail },
};
</script>
