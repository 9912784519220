<template>
  <Main>
    <template v-slot:content>
      <b-row class="mt-3 justify-content-center">
        <b-col xl="6" lg="7" md="10" sm="12">
          <div class="main-title" id="mainTitle">
            Final step: Check your email and verify your account
          </div>
          <div class="subtitle mt-4">
            We've sent you a verification email to
            <b>{{ email }}</b>
          </div>
          <SubmitButton
            class="mt-5"
            text="Resend verification email"
            id="resendVerificationEmail"
            :loading="loading"
            @onClick="resendVerificationEmail"
          />
        </b-col>
        <b-col
          xl="6"
          lg="5"
          md="12"
          class="d-flex justify-content-end align-items-center info-card pr-5"
        >
          <GameChangerCard />
        </b-col>
      </b-row>
      <ToastSuccess text="Verification email was sent!" />
      <ToastError
        text="There was a problem sending an email. Please try again later."
      >
        <template>
          <div>Error: {{ toastMessage }}</div>
        </template>
      </ToastError>
    </template>
  </Main>
</template>

<script>
import GameChangerCard from "@/components/cards/GameChangerCard";
import SubmitButton from "@/components/buttons/SubmitButton";
import { logging } from "@/api/logs";
import { mix_panel } from "@/services/common/mixpanel";
import { verification } from "@/services/verification";
import Main from "@/layout/Main";
import { store } from "@/store";
import { email_sender_constants } from "@/services/common/constants";
import ToastSuccess from "@/components/toast/ToastSuccess";
import ToastError from "@/components/toast/ToastError";
export default {
  name: "index",
  components: { ToastError, ToastSuccess, Main, SubmitButton, GameChangerCard },
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  async mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      store.commit("auth/updateEmail", this.email);
    }

    if (this.$route.query.resend) {
      await this.resendVerificationEmail();
    }
  },
  methods: {
    async resendVerificationEmail() {
      logging.sendLog("Verification Email requested", 3);
      await mix_panel.onBasicEvent("VerificationEmailRequested", "");
      this.loading = true;

      try {
        await verification.resendEmail(store.state.auth.email, "verify");
        this.$bvToast.show("toast-success");
      } catch (e) {
        logging.sendLog("Verification email was not sent!", 6);
        this.toastMessage = email_sender_constants.NOT_SENT;
        this.$bvToast.show("toast-error");
      }

      this.loading = false;
    },
  },
};
</script>
