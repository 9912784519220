import { store } from "@/store";

var mixpanel = require("mixpanel-browser");

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);
export const mix_panel = {
  async identify(parameter) {
    mixpanel.identify(parameter);
    let parameters = store.state.data.utmParams;
    parameters["$email"] = parameter;
    await this.setUserProperties(parameters);
  },
  async setUserProperties(properties, callback) {
    mixpanel.people.set(properties, callback);
  },
  async onBasicEvent(property, value, additionalProperties = {}, callback) {
    await mixpanel.track(
      "LoopRegistrationFlow",
      {
        action: property,
        value: value,
        Application: "Registration",
        referral: store.state.log.referralPage || "None",
        ipAddress: store.getters["auth/ipAddress"],
        email: store.getters["auth/email"],
        firstEmail: store.getters["auth/firstEmail"],
        origin: store.state.data.origin,
        ...additionalProperties,
      },
      { send_immediately: true },
      callback
    );
  },
};
