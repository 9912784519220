<template>
  <Main>
    <template v-slot:content>
      <b-row class="mt-3 justify-content-center">
        <b-col xl="6" lg="7" md="10" sm="12">
          <div class="main-title">
            Success, your password was successfully changed!
          </div>
          <div class="subtitle mt-4">
            Click below to get back into your Loop app!
          </div>
          <SubmitButton
            class="mt-5"
            text="Get back into the app!"
            @onClick="backToTheApp"
          />
        </b-col>
        <b-col
          xl="6"
          lg="5"
          md="12"
          class="d-flex justify-content-end align-items-center info-card pr-5"
        >
          <GameChangerCard />
        </b-col>
      </b-row>
    </template>
  </Main>
</template>

<script>
import GameChangerCard from "@/components/cards/GameChangerCard";
import SubmitButton from "@/components/buttons/SubmitButton";
import { logging } from "@/api/logs";
import { mix_panel } from "@/services/common/mixpanel";
import Main from "@/layout/Main";
export default {
  name: "success",
  components: { Main, SubmitButton, GameChangerCard },
  methods: {
    async backToTheApp() {
      logging.sendLog("Back to the app clicked", 3);
      await mix_panel.onBasicEvent("BackToAppClicked", "");
      window.location = "intheloop://close-web-onboarding";
    },
  },
};
</script>
