<template>
  <div>
    <b-row class="justify-content-center">
      <b-col sm="12" md="9" lg="12">
        <EmailInput
          id="email"
          :field="$v.form.email"
          :state="validateState($v.form, 'email')"
          :server-errors="serverErrors['email']"
          @update="$v.form.email.$model = $event"
          @keyupEnter="login"
          label="Email"
          field-name="email"
        />
      </b-col>
      <b-col sm="12" md="9" lg="12">
        <PasswordInput
          id="password"
          :field="$v.form.password"
          :state="validateState($v.form, 'password')"
          :server-errors="serverErrors['password']"
          @update="$v.form.password.$model = $event"
          @keyupEnter="login"
          label="Password"
          field-name="password"
        />
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col sm="12" md="9" lg="12">
        <SubmitButton
          :loading="loading"
          :text="button_text"
          class="mt-4"
          @onClick="login"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3 sign-up-text text-center">
      <b-col>
        <div>
          Forgot password?
          <span class="color-loop clickable" @click="forgotPassword">
            Click here!
          </span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EmailInput from "@/components/inputs/EmailInput";
import PasswordInput from "@/components/inputs/PasswordInput";
import login from "@/mixins/loopLogin/login";
import SubmitButton from "@/components/buttons/SubmitButton";

export default {
  name: "LoginForm",
  components: { SubmitButton, PasswordInput, EmailInput },
  mixins: [login],
  props: {
    button_text: { type: String, default: () => "Login" },
  },
};
</script>
