<template>
  <Main>
    <template v-slot:content>
      <b-row class="mt-3 justify-content-center">
        <b-col xl="6" lg="7" md="10" sm="12">
          <div class="main-title">Login</div>
          <LoginForm class="mt-3" @success="success" />
        </b-col>
        <b-col
          xl="6"
          lg="5"
          md="12"
          class="d-flex justify-content-end align-items-center info-card pr-5"
        >
          <GameChangerCard />
        </b-col>
      </b-row>
    </template>
  </Main>
</template>

<script>
import LoginForm from "@/components/forms/loopLogin/LoginForm";
import GameChangerCard from "@/components/cards/GameChangerCard";
import Main from "@/layout/Main";
export default {
  name: "index",
  components: { Main, GameChangerCard, LoginForm },
  methods: {
    success() {
      this.$router.push("/code");
    },
  },
};
</script>
