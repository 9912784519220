<template>
  <div>
    <div class="pl-md-0 pr-md-0 h-100">
      <div class="h-100 wrapper">
        <b-container
          fluid
          class="h-100 d-flex flex-column text-lg-left text-center pl-5 pr-5 pl-lg-0 pr-lg-0"
        >
          <b-row class="h-100">
            <b-col>
              <LogoComponent />
              <b-container fluid class="h-100">
                <slot />
              </b-container>
            </b-col>
          </b-row>
          <b-row
            class="mt-auto justify-content-center align-content-center"
            style="height: 80px"
          >
            <b-col xl="9" lg="11" md="12">
              <slot name="footer" />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <RadialGradient
      style="position: absolute; right: 170px; top: 50px; z-index: -1"
      color="#F5A632"
    />
    <EmptyCircle
      v-if="topCircle"
      style="position: absolute; top: -30px; left: 340px; z-index: -1"
    />
    <div class="background-radial-left" />
    <CircleElement
      style="
        position: absolute;
        right: -10px;
        top: calc(-30px + 65vh);
        z-index: -1;
      "
    />
    <EllipseElement
      style="
        position: absolute;
        right: -10px;
        top: calc(-330px + 65vh);
        z-index: -1;
      "
    />
    <RadialGradient
      style="position: absolute; left: 110px; top: 180px; z-index: -1"
      color="#5640B2"
    />
    <slot name="additional-components" />
  </div>
</template>

<script>
import EmptyCircle from "@/components/nonFunctional/EmptyCircle";
import LogoComponent from "@/components/Home/NewDownload/LogoComponent";
import CircleElement from "@/components/nonFunctional/CircleElement";
import EllipseElement from "@/components/nonFunctional/EllipseElement";
import RadialGradient from "@/components/nonFunctional/RadialGradient";
export default {
  name: "LoopLeft",
  components: {
    RadialGradient,
    EllipseElement,
    CircleElement,
    LogoComponent,
    EmptyCircle,
  },
  props: {
    topCircle: { type: Boolean, default: () => true },
  },
};
</script>

<style lang="scss" scoped>
.background-radial-left {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -2;
  background: radial-gradient(140% 140% at top, #efeef2 60.05%, #ffffff 60%);
}
</style>
