import base from "@/mixins/loopLogin/base";
import { password_login } from "@/services/oauth/password";
import { mix_panel } from "@/services/common/mixpanel";
import { logging } from "@/api/logs";
import { store } from "@/store";
import { common } from "@/services/common/common";
import { constants } from "@/services/common/constants";

export default {
  extends: base,
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.email && this.$route.query.email.length > 0) {
      this.form.email = this.$route.query.email;
    }
  },
  methods: {
    async login() {
      this.loading = true;
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        logging.sendLog("FormSubmitted", 3);
        await mix_panel.onBasicEvent("FormSubmitted", "");
        try {
          let response = await password_login.login(
            this.form.email,
            this.form.password
          );
          if (response.data.token.accessToken) {
            store.commit(
              "auth/updateAccessToken",
              response.data.token.accessToken
            );
            this.$emit("success");
          }
        } catch (e) {
          await this.processErrors(e);
        }
      } else {
        logging.sendLog("Form invalid", 3);
        await mix_panel.onBasicEvent("FormInvalid", "");
      }
      this.loading = false;
    },
    forgotPassword() {
      this.$router.push("/forgot-password/send-email");
    },
    async processErrors(e) {
      if (e.response.data.message.includes("not exist")) {
        logging.sendLog(`User does not exists ${e.response.data.message}`, 6);
        await mix_panel.onBasicEvent(
          constants.SERVER_ERROR,
          "UserDoesNotExist"
        );
        this.serverErrors = {
          email: [common.EMAIL_NOT_YET_REGISTERED],
        };
        this.$v.form.email.$touch();
      } else if (e.response.data.message.includes("password")) {
        logging.sendLog(`Wrong password`, 3);
        await mix_panel.onBasicEvent(constants.LOGIN_ERROR, "WrongPassword");
        this.serverErrors = {
          password: [common.WRONG_PASSWORD],
        };
        this.$v.form.password.$touch();
      }
    },
  },
};
