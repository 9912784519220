<template>
  <LoopLeft>
    <div class="h-100 text-center intro-padding intro align-items-center">
      <div class="intro-heading position-relative">
        <div>Get Loop on all your devices</div>
      </div>
      <b-spinner small v-if="loading" />
      <div v-else class="mt-3">
        <small>
          {{ release.tag_name }}
          <a
            class="link-intro ml-3"
            target="_blank"
            href="https://www.intheloop.io/learn-more/cbxchangelog/whats-changed-in-loop/"
            @click="whatsNew"
          >
            What's new?
          </a>
        </small>
        <br />
        <div class="mt-3">
          <small>
            Last updated
            <span>
              {{ months[publishedAt.getMonth()] }}
              {{ publishedAt.getDate() }}{{ getNth(publishedAt.getDate()) }},
              {{ publishedAt.getFullYear() }}
            </span>
          </small>
        </div>
      </div>
      <b-row
        class="justify-content-center align-items-end pb-5"
        style="margin-top: 5.5%"
      >
        <Iphone class="d-block position-relative mr-0 mr-sm-3" />
        <div class="w-100 d-block d-sm-none mt-5" />
        <Android class="d-block d-lg-none" />
        <div class="w-100 d-block d-lg-none mt-5" />
        <DesktopApp
          class="desktop-app-wrapper d-inline-block position-relative"
        />
        <div class="w-100 d-block d-lg-none mt-5" />
        <Android
          class="mobile-app-wrapper d-none d-lg-inline-block position-relative"
        />
        <!--        <MobileApp-->
        <!--          class="mobile-app-wrapper d-inline-block position-relative"-->
        <!--        />-->
      </b-row>
    </div>
  </LoopLeft>
</template>

<script>
import { Octokit } from "@octokit/rest";
import { mix_panel } from "@/services/common/mixpanel";
import { tracking_constants } from "@/services/common/constants";
import newDownload from "@/mixins/newDownload/newDownload";
import DesktopApp from "@/components/Home/NewDownload/DesktopApp";
import LoopLeft from "@/layout/LoopLeft";
import Android from "@/components/Home/NewDownload/Android";
import Iphone from "@/components/Home/NewDownload/Iphone";
import { mapMutations } from "vuex";

export default {
  name: "Download",
  components: {
    Iphone,
    Android,
    LoopLeft,
    DesktopApp,
  },
  mixins: [newDownload],
  data() {
    return {
      publishedAt: new Date(),
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      loading: true,
      release: {},
      octokit: {},
    };
  },
  async mounted() {
    this.loading = true;
    this.octokit = new Octokit({
      auth: "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",
    });
    let releases = await this.getRelease(0);
    this.publishedAt = new Date(releases[0].published_at);
    this.release = releases[0];
    this.loading = false;
    if (this.$route.query.email) {
      let properties = {};
      this.updateFirstEmail(this.$route.query.email);
      this.updateEmail(this.$route.query.email);
      properties[tracking_constants.EMAIL] = this.$route.query.email;
      mix_panel.setUserProperties(properties);
    }
  },
  methods: {
    ...mapMutations("auth", ["updateFirstEmail", "updateEmail"]),
    async getRelease(page) {
      let releases = await this.octokit.rest.repos.listReleases({
        owner: "4thOffice",
        repo: "loopinupdater",
        per_page: 100,
        page: page,
      });
      releases = releases.data.filter(
        (release) => !release.prerelease && !release.draft
      );

      if (releases.length === 0) {
        releases = await this.getRelease(++page);
      }

      return releases;
    },
  },
};
</script>

<style lang="scss" scoped></style>
