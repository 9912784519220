<template>
  <div v-if="canShow">
    <b-row class="justify-content-center">
      <b-col>
        <div class="title-wrapper">
          <div class="title title-big">Join {{ invitedBy }} on Loop</div>
          <div class="sub-title text-left hide">
            You've been invited to join Loop, click continue to finish your
            set-up.
          </div>
        </div>
      </b-col>
      <b-col cols="12" class="hide" style="margin-top: 40px">
        <div
          class="d-flex justify-content-center justify-content-lg-start align-items-center"
        >
          <img
            src="@/assets/img/invite/Team.svg"
            style="width: 13vw; min-width: 80px"
            class="mb-3"
            alt="TeamSvg"
          />
          <div class="text-left pl-5">
            <div class="text-left title">It's perfect for teams like us</div>
            <div class="mt-3 sub-title text-left">
              Loop is designed to deliver all-in-one
              <br />
              collaboration, right in your inbox.
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { common } from "../../services/common/common";
import { store } from "../../store";
import { constants } from "../../services/common/constants";
import OriginEnum from "../../helpers/enums/OriginEnum";
import { mapMutations } from "vuex";

export default {
  name: "Invite",
  computed: {
    isEmail() {
      if (this.forced) {
        return false;
      } else if (this.email === undefined || this.email.length === 0) {
        return null;
      }
      return common.isEmail(this.email) ? null : false;
    },
  },
  data() {
    return {
      email: "",
      state: null,
      invitedBy: "Loop",
      canShow: false,
      forced: false,
    };
  },
  async mounted() {
    if (this.$route.query.invited_by) {
      this.invitedBy = this.$route.query.invited_by;
    }
    this.setOrigin(OriginEnum.WEBAPP_PROD);
    this.canShow = true;
  },
  methods: {
    ...mapMutations("data", ["setOrigin"]),
    next() {
      if (this.email.length === 0) {
        this.forced = true;
        this.errorMessage = constants.requiredField("email address");
      } else if (!common.isEmail(this.email)) {
        this.forced = true;
      } else {
        localStorage.invited = "true";
        store.commit("auth/updateEmail", this.email);
        this.$router.push(
          `/?email=${this.email}&intention=invited&from=invite`
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
#email {
  border-radius: 40px 0 0 40px;
  border-left: 1px solid #f5a632;
  border-top: 1px solid #f5a632;
  border-right: none;
  border-bottom: 1px solid #f5a632;
  padding: 13px 24px 13px;
  width: 300px;
  height: 56px;
  font-size: 18px;
  &:focus {
    box-shadow: unset !important;
  }
}
.marketing_button {
  background: #f5a632;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 12px 15px;
  border: 1px solid #f5a632;
  border-radius: 0 40px 40px 0;
  cursor: pointer;
  min-width: 170px;
  height: 56px;
  &:active {
    box-shadow: unset;
    background-color: #f5a632 !important;
    border: unset !important;
  }
  &:focus {
    box-shadow: unset;
  }
}
.custom-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #313032;
}
.custom-subtitle {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #313032;
}
.custom-error {
  position: relative;
}
.title-wrapper {
  @media (max-width: 1100px) {
    text-align: center;
  }
  @media (max-height: 888px) {
    text-align: center;
  }
}
.hide {
  @media (max-width: 1100px) {
    display: none;
  }
  @media (max-height: 888px) {
    display: none;
  }
}
</style>
