<template>
  <BaseInput
    :id="id"
    :field="field"
    :state="state"
    :label="label"
    :ref="id"
    :place-holder="placeHolder"
    :class="{ 'free-domain': isFreeDomain }"
    @update="onUpdate"
    @keyupEnter="$emit('keyupEnter')"
    type="email"
  >
    <b-form-invalid-feedback
      v-if="'required' in field && !field.required"
      class="input-error"
    >
      {{ constants.requiredField(fieldName) }}
    </b-form-invalid-feedback>
    <b-form-invalid-feedback
      v-else-if="'email' in field && !field.email"
      class="input-error"
    >
      {{ constants.INVALID_EMAIL }}
    </b-form-invalid-feedback>
    <b-form-invalid-feedback
      v-for="error in serverErrors"
      :key="error"
      class="input-error"
    >
      <div v-if="error === 'EMAIL_USED'">
        This email address is already registered.
        <br />
        <router-link to="/forgot-password/send-email"
          >Reset your password</router-link
        >
        if you've forgotten it.
      </div>
      <div v-else-if="error === 'EMAIL_HAS_SYNC'">
        This email address already exists. You can log in
        <a class="link-intro" href="intheloop://close-web-onboarding">here.</a>
      </div>
      <div v-else>{{ error }}</div>
    </b-form-invalid-feedback>
    <div v-if="checkDomain && isFreeDomain && state" class="input-info">
      {{ constants.RECOMMEND_WORK_EMAIL }}
    </div>
  </BaseInput>
</template>

<script>
import BaseInput from "@/components/inputs/BaseInput";
import { constants } from "@/services/common/constants";
import domains from "@/mixins/helpers/domains";

export default {
  name: "EmailInput",
  components: { BaseInput },
  props: {
    id: { type: String, required: true },
    label: { type: String },
    field: { type: Object, required: true },
    fieldName: { type: String, required: true },
    serverErrors: { type: Array, default: () => [] },
    state: { type: [Boolean] },
    placeHolder: { type: String },
    checkDomain: { type: [Boolean] },
  },
  mixins: [domains],
  data() {
    return {
      constants: constants,
      isFreeDomain: false,
    };
  },
  methods: {
    onUpdate(event) {
      this.$emit("update", event);

      if (this.checkDomain) {
        this.isFreeDomain = !this.isBusinessDomain(event);
      }
    },
  },
};
</script>
