<template>
  <div>
    <div class="d-flex align-items-end">
      <a
        class="download-link image-link"
        :href="allSystems[2].downloadLink"
        target="_blank"
        rel="noopener noreferrer"
        @click="download(allSystems[2].name)"
      >
        <div class="d-flex justify-content-center mb-3">
          <img
            src="https://dl.intheloop.io/onboarding/apple.png"
            alt="apple.png"
            height="140px"
          />
        </div>
        <img
          height="45px"
          src="https://dl.intheloop.io/onboarding/apple_store.svg"
          alt="apple_store.png"
        />
      </a>
    </div>
  </div>
</template>

<script>
import newDownload from "@/mixins/newDownload/newDownload";

export default {
  name: "Iphone",
  mixins: [newDownload],
};
</script>
