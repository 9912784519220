import api from "@/client/client";

const route = "/api/v1/auth/";

export const auth = {
  getEmailType(email) {
    return api.get(`/emailType/${email}`);
  },
  registerMicrosoft(data) {
    return api.post(`${route}microsoft`, data);
  },
  registerGoogle(data) {
    return api.post(`${route}gmail`, data);
  },
  registerExchange(data) {
    return api.post(`${route}exchange`, data);
  },
  registerImapNative(data) {
    return api.post(`${route}imap`, data);
  },
  registerImap(data) {
    return api.post(`${route}remoteimap`, data);
  },
  loopLogin(data) {
    return api.post(`${route}password`, data);
  },
  gmailProfile(data) {
    return api.post(`${route}gmailprofile`, data);
  },
  microsoftProfile(data) {
    return api.post(`${route}microsoftprofile`, data);
  },
};
