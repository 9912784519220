const crypto = require("crypto");

const cryptoKeyIv = "kYp3s6v8y/B?E(H+";
const iv = "$C&F)J@NcQfTjWnZ";
const algorithm = "aes-128-cbc";

export const encryption = {
  decrypt(value) {
    let decipher = crypto.createDecipheriv(algorithm, cryptoKeyIv, iv);
    value = decipher.update(value, "base64", "utf8");
    value += decipher.final("utf8");
    return value;
  },

  encrypt(value) {
    let cipher = crypto.createCipheriv(algorithm, cryptoKeyIv, iv);
    value = cipher.update(value, "utf8", "base64");
    value += cipher.final("base64");
    return encodeURIComponent(value);
  },
};
