<template>
  <LoopLeft class="h-100">
    <template>
      <b-row
        class="mt-3 justify-content-center text-center h-100 align-items-center"
      >
        <b-col xl="6" lg="7" md="10" sm="12">
          <div class="main-title-small subtitle-green" id="mainTitleSuccess">
            User successfully logged out
          </div>
          <div class="subtitle mt-4">You can now close this window</div>
        </b-col>
      </b-row>
    </template>
  </LoopLeft>
</template>

<script>
import LoopLeft from "@/layout/LoopLeft";
export default {
  name: "index.vue",
  components: {
    LoopLeft,
  },
};
</script>

<style scoped></style>
