import { logging } from "@/api/logs";
import { mix_panel } from "@/services/common/mixpanel";
import { verification } from "@/services/verification";
import { features } from "@/services/common/features";
import { mapActions, mapMutations, mapState } from "vuex";
import domains from "@/mixins/helpers/domains";
export default {
  watch: {
    $route(to) {
      this.url_purpose = to.params.url_purpose;
    },
  },
  mixins: [domains],
  data() {
    return {
      verifying: true,
      failed: false,
      loading: false,
      url_purpose: "",
    };
  },
  computed: {
    ...mapState({
      email: (state) => state.auth.email,
      purpose: (state) => state.auth.purpose,
    }),
  },
  async mounted() {
    this.url_purpose = this.$route.params.url_purpose;

    if (!features.purposes.includes(this.url_purpose)) {
      this.$router.push("/");
    }

    try {
      let email = this.$route.query.email;
      let key = this.$route.query.key;
      let purpose = this.$route.query.purpose;
      this.updateVerifyData([email, key, purpose]);
      this.updateEmail(email);
      this.updateFirstEmail(email);

      let response = await verification.verify(email, key, purpose);
      logging.sendLog(`Email verified ${email}`, 3);
      await mix_panel.onBasicEvent("EmailVerified", "");

      switch (this.url_purpose) {
        case "activate":
          this.verifying = false;
          break;
        case "verify":
          this.verifying = false;
          this.failed = false;
          this.redirectToApp(response);
          break;
        case "reset-password":
          await this.$router.push("/forgot-password");
          break;
        case "deleteuseraccess":
          await this.$router.push("/auth/user-logout");
          break;
      }
    } catch (e) {
      logging.sendLog(`Email not verified ${JSON.stringify(e.response)}`, 6);
      await mix_panel.onBasicEvent("EmailNotVerified", "");
      this.failed = true;
      this.verifying = false;
    }
  },
  methods: {
    ...mapActions("auth", ["updateVerifyData"]),
    ...mapMutations("auth", ["updateFirstEmail", "updateEmail"]),
    async backToTheApp() {
      logging.sendLog("Back to the app clicked", 3);
      await mix_panel.onBasicEvent("BackToAppClicked", "");
      window.location = "intheloop://close-web-onboarding";
    },
    redirectToApp(response) {
      let code = response.data.code;
      let [domainType, properties] = this.getEmailDomainUrlParameter(
        this.$store.state.auth.email
      );
      mix_panel.setUserProperties(properties, async () => {
        this.$router.push(
          `/success?signup_complete=true&code=${code}&email_domain=${domainType}`
        );
      });
    },
    async resendVerificationEmail() {
      this.loading = true;
      let response = await verification.resendEmail(
        this.email,
        this.url_purpose
      );
      if (response) {
        this.$bvToast.show("toast-success");
      } else {
        this.$bvToast.show("toast-error");
      }
      this.loading = false;
    },
  },
};
