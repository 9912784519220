import { store } from "@/store";
import router from "@/router";
import logClient from "@/client/logClient";
import deviceId from "device-uuid";
const crypto = require("crypto");
const platform = require("platform");
import { common } from "@/services/common/common";

const route = "/api/log";

let aesKey = Buffer.from(
  "28482B4D6251655468576D5A7134743777217A25432A462D4A404E635266556A",
  "hex"
);
let aesIv = Buffer.from("38782F413F442A472D4B615064536756", "hex");

/*
 level:
  3 - info
  4 - warning
  5 - error
  6 - critical
 */
export const logging = {
  async sendLog(longMessage, level, additionalData = []) {
    let log_json = {};
    if (typeof longMessage === "string" || longMessage instanceof String) {
      log_json.message = longMessage;
    } else {
      log_json.message = JSON.stringify(longMessage);
    }
    if (!log_json.message) {
      log_json.message = "empty";
    }
    log_json.level = level;
    log_json.email = store.getters["auth/email"];

    if (!localStorage.machineId) {
      localStorage.machineId = new deviceId.DeviceUUID().get();
      log_json.deviceId = localStorage.machineId;
    } else {
      log_json.deviceId = localStorage.machineId;
    }

    log_json.clientAppId = "Registration";
    // mock
    log_json.customStringArgs = [
      { name: "browserName", value: platform.name || "" },
      { name: "browserVersion", value: platform.version || "" },
      { name: "browserProduct", value: platform.product || "" },
      { name: "browserManufacturer", value: platform.manufacturer || "" },
      { name: "location", value: router.history.current.path || "" },
      { name: "requestId", value: common.makeId(16) || "" },
      { name: "sessionId", value: store.getters["log/getSessionId"] },
      { name: "firstEmail", value: store.getters["auth/firstEmail"] },
      { name: "origin", value: store.getters["data/origin"] },
    ];
    for (let data of additionalData) {
      log_json.customStringArgs.push(data);
    }
    log_json.clientAppVersion = "0.0.1";
    log_json.localTime = new Date().toISOString();

    if (process.env.VUE_APP_ENV !== "dev") {
      return logClient.post(route, [log_json]);
    }
    return logClient.post(route, [log_json]);
  },
  getCryptedLogBearer(logBearer) {
    const algorithm = "aes-256-cbc";
    const cipher = crypto.createCipheriv(algorithm, aesKey, aesIv);
    let encrypted = cipher.update(logBearer, "utf8", "base64");
    encrypted += cipher.final("base64");
    return encrypted;
  },
};
