import base from "@/mixins/loopLogin/base";
import { verification } from "@/services/verification";
import { email_sender_constants } from "@/services/common/constants";
import { mapMutations } from "vuex";
import { logging } from "@/api/logs";

export default {
  extends: base,
  data() {
    return {
      loading: false,
      response: "",
      toastMessage: "",
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.updateFirstEmail(this.$route.query.email);
      this.updateEmail(this.$route.query.email);
      this.form.email = this.$route.query.email;
    }
  },
  methods: {
    ...mapMutations("auth", ["updateFirstEmail", "updateEmail"]),
    async sendEmail() {
      this.loading = true;
      this.$v.form.email.$touch();
      if (!this.$v.form.email.$anyError) {
        logging.sendLog("Form submitted -> Forgot password", 3);
        this.updateEmail(this.form.email);
        let response = await verification.sendForgotEmail(this.form.email);
        await this.processResponse(response);
      }
      this.loading = false;
    },
    async processResponse(response) {
      if (response.data.emailSend) {
        this.$router.push(
          `/forgot-password/email-sent?email=${this.form.email}`
        );
      } else {
        this.toastMessage = email_sender_constants.NOT_SENT;
        this.$bvToast.show("toast-error");
      }
    },
  },
};
