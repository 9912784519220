<template>
  <LoopLeft class="h-100">
    <div
      class="text-center d-flex justify-content-center align-items-center h-100"
    >
      <div v-if="isWebApp">
        <div class="main-title">Redirecting you to Loop...</div>
      </div>
      <div
        v-else
        class="justify-content-center align-content-center align-items-center"
      >
        <div v-if="oauthError">
          <div class="main-title">Oops!</div>
          <div class="subtitle mt-4">
            Permission was denied. Please try again.
          </div>
        </div>
        <div v-else-if="isOauthForSIorEnableSync">
          <div class="main-title">One last step</div>
          <div class="subtitle mt-4">
            {{
              isOauthForSI
                ? "Click on the button below to finish connecting your shared inbox"
                : "Click on the button below to finish connecting your personal inbox"
            }}
          </div>
        </div>
        <div v-else-if="!$route.query.login">
          <div class="main-title">Congratulations!</div>
          <div class="subtitle mt-4">
            Your account has been successfully created. Click below to get
            started.
          </div>
        </div>
        <div v-else>
          <div class="main-title">Welcome back!</div>
          <div class="subtitle mt-4">
            We have identified that you are an existing Loop user. Use the
            button below to log back into Loop.
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <b-spinner variant="primary" v-if="loading" />
          <SubmitButton
            v-else
            class="mt-5"
            :text="submitButtonText"
            @onClick="backToTheApp"
          />
        </div>
      </div>
    </div>
  </LoopLeft>
</template>

<script>
import SubmitButton from "@/components/buttons/SubmitButton";
import { logging } from "@/api/logs";
import { mix_panel } from "@/services/common/mixpanel";
import OriginEnum from "@/helpers/enums/OriginEnum";
import DeeplinkEnum from "@/helpers/enums/DeeplinkEnum";
import LoopLeft from "@/layout/LoopLeft";
import { token } from "@/api/token";
import redirect from "../../mixins/helpers/redirect";
import {
  getExtraOathParams,
  isOauthForSIorEnableSync,
  isOauthForSI,
} from "../../mixins/helpers/redirect";
import AuthType from "../../helpers/enums/AuthType";

export default {
  name: "index",
  components: {
    LoopLeft,
    SubmitButton,
  },
  mixins: [redirect],
  data() {
    return {
      loading: true,
      isWebApp: false,
      oauthError: false,
      isOauthForSIorEnableSync: false,
      isOauthForSI: false,
      submitButtonText: " Go to the app",
    };
  },
  async mounted() {
    this.loading = true;
    this.oauthError = !!localStorage.oauthError;
    this.isOauthForSIorEnableSync = isOauthForSIorEnableSync();
    this.isOauthForSI = isOauthForSI();

    if (this.isOauthForSIorEnableSync) {
      this.submitButtonText = this.isOauthForSI
        ? "Connect shared inbox"
        : "Connect personal inbox";
    }

    delete localStorage.oauthError;

    if (
      this.$store.state.data.origin.includes("web") ||
      this.$store.state.data.origin.includes("marketing")
    ) {
      this.isWebApp = true;
    }

    try {
      if (this.$route.query.code !== undefined) {
        this.code = this.$route.query.code;
      } else if (!this.oauthError) {
        let response = await token.getCode();
        this.code = response.data.code;
      }

      if (this.isOauthForSIorEnableSync) {
        this.redirectToApp();
      } else if (this.$route.query.signup_complete) {
        await mix_panel.onBasicEvent(
          "Signup completed",
          "true",
          {},
          this.redirectToApp
        );
      } else {
        await mix_panel.onBasicEvent(
          "Login completed",
          "true",
          {},
          this.redirectToApp
        );
      }
    } catch (e) {
      this.$router.push(`/?from=${this.$store.state.data.origin}`);
    }
    this.loading = false;
  },
  methods: {
    redirectToApp() {
      if (this.isWebApp) {
        this.redirectToWeb(
          this.code,
          this.$route.query.code !== undefined
            ? AuthType.AuthPassword
            : AuthType.AuthCode,
          this.$store.state.auth.email,
          this.oauthError
        );
      }
    },
    async backToTheApp() {
      logging.sendLog(
        `Back to the app clicked ${this.$store.state.auth.strategy}`,
        3
      );
      await mix_panel.onBasicEvent(
        "BackToAppClicked",
        this.$store.state.auth.strategy
      );

      this.processStrategy(this.$store.state.auth.strategy === "loopLogin");
    },
    processStrategy(isLoopLogin) {
      switch (this.$store.state.data.origin) {
        case OriginEnum.DTA:
          isLoopLogin
            ? (window.location = "intheloop://close-web-onboarding")
            : (window.location = `intheloop://code-login?code=${
                this.code
              }${getExtraOathParams(
                this.$store.state.auth.email,
                this.oauthError
              )}`);
          break;
        case OriginEnum.MOBILE:
          isLoopLogin
            ? (window.location = "intheloop://close-web-onboarding")
            : (window.location = `intheloop://code-login?code=${this.code}&email-type=${this.$store.state.data.emailAuthType}`);
          break;
        case OriginEnum.MOBILE_V2:
          isLoopLogin
            ? (window.location = "intheloop://close-web-signin")
            : (window.location = `intheloop://code-login?code=${this.code}&email-type=${this.$store.state.data.emailAuthType}`);
          break;
        case OriginEnum.INVITE:
          isLoopLogin
            ? (window.location = "intheloop://close-web-onboarding")
            : (window.location = `intheloop://code-login?code=${this.code}&email-type=${this.$store.state.data.emailAuthType}&web-app-deeplink=${DeeplinkEnum.CODE_LOGIN}`);
          break;
      }
    },
  },
};
</script>
