<template>
  <Main>
    <template v-slot:content>
      <b-row class="mt-3 justify-content-center">
        <b-col xl="6" lg="7" md="10" sm="12">
          <div class="main-title">Change email</div>
          <Step1 v-if="step === 1" @next="next" />
          <Step2 v-if="step === 2" />
        </b-col>
        <b-col
          xl="6"
          lg="5"
          md="12"
          class="d-flex justify-content-end align-items-center info-card pr-5"
        >
          <GameChangerCard />
        </b-col>
      </b-row>
    </template>
  </Main>
</template>

<script>
import GameChangerCard from "@/components/cards/GameChangerCard";
import Step1 from "@/components/changeEmail/Step1";
import Step2 from "@/components/changeEmail/Step2";
import Main from "@/layout/Main";
export default {
  name: "index",
  components: { Main, Step2, Step1, GameChangerCard },
  watch: {
    $route(to) {
      this.step = parseInt(to.params.step);
    },
  },
  data() {
    return {
      step: 1,
    };
  },
  mounted() {
    localStorage.clear();
    this.step = parseInt(this.$route.params.step);
  },
  methods: {
    next() {
      this.step = this.step + 1;
      this.$router.push(`/change-email/${this.step}`);
    },
  },
};
</script>
