<template>
  <img src="@/assets/img/logo-blue.svg" alt="logo" height="46" />
</template>

<script>
export default {
  name: "Logo",
  props: {
    custom_class: {
      type: String,
      default: function () {
        return "text-lg-left mb-4";
      },
    },
  },
};
</script>
