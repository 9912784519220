<template>
  <div>
    <b-row class="justify-content-center">
      <b-col sm="12" md="8" lg="6">
        <PasswordInput
          id="password"
          :field="$v.form.password"
          :state="validateState($v.form, 'password')"
          :server-errors="serverErrors['password']"
          @update="$v.form.password.$model = $event"
          @keyupEnter="submitForm"
          label="New password"
          field-name="password"
        />
        <PasswordInput
          id="passwordRepeat"
          :field="$v.form.repeatPassword"
          :state="validateState($v.form, 'repeatPassword')"
          :server-errors="serverErrors['repeatPassword']"
          @update="$v.form.repeatPassword.$model = $event"
          @keyupEnter="submitForm"
          label="Re-enter new password"
          field-name="password"
        />
        <SubmitButton
          text="Confirm"
          class="mt-4"
          :loading="loading"
          @onClick="submitForm"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PasswordInput from "@/components/inputs/PasswordInput";
import setPassword from "@/mixins/loopLogin/setPassword";
import SubmitButton from "@/components/buttons/SubmitButton";
export default {
  name: "SetNewPassword",
  components: { SubmitButton, PasswordInput },
  mixins: [setPassword],
};
</script>
