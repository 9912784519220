<template>
  <b-form-group class="custom-input text-left">
    <div style="max-width: 800px" class="mx-auto">
      <label v-if="label.length > 0" class="font-label" :for="id">{{
        label
      }}</label>
      <b-form-input
        :id="id"
        :ref="id"
        :value="field.$model"
        @input="updateField"
        :state="state"
        :type="type"
        :placeholder="placeHolder"
        :autofocus="autofocus"
        @keyup.enter="$emit('keyupEnter')"
      />
      <slot></slot>
    </div>
  </b-form-group>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    id: { type: String, required: true },
    type: { type: String, required: true },
    field: { type: Object, required: true },
    state: { type: [Boolean] },
    autofocus: { type: [Boolean], default: () => false },
    placeHolder: { type: String },
    label: { type: String, default: () => "" },
  },
  data() {
    return {
      event: "update",
    };
  },
  methods: {
    updateField(event) {
      this.$emit("update", event);
    },
  },
};
</script>
