<template>
  <b-button v-if="loading" :class="customClass">
    <div class="invisible" style="height: 0 !important">
      <div
        class="d-flex justify-content-center align-content-center justify-content-between"
      >
        <div class="d-flex justify-content-center align-content-center">
          <slot name="button-image" />
        </div>
        {{ text }}
        <div></div>
      </div>
    </div>
    <div class="d-flex justify-content-center text-center">
      <b-spinner style="width: 25px; height: 25px; border-width: 5px" />
    </div>
  </b-button>
  <b-button v-else :class="customClass" @click="$emit('onClick')">
    <div
      class="d-flex justify-content-center align-content-center justify-content-between"
    >
      <div class="d-flex justify-content-center align-content-center">
        <slot name="button-image" />
      </div>
      {{ text }}
      <div></div>
    </div>
  </b-button>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {
    text: { type: String, required: true },
    loading: { type: Boolean },
    customClass: {
      type: String,
      default: () => "custom-button custom-button-next w-100",
    },
  },
};
</script>
