import { minLength, required, sameAs } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";
import { logging } from "@/api/logs";
import { mix_panel } from "@/services/common/mixpanel";
import { store } from "@/store";

export default {
  mixins: [validation],
  data() {
    return {
      form: {
        email: "",
        password: "",
        repeatPassword: "",
      },
      loading: false,
    };
  },
  validations: {
    form: {
      email: { required },
      password: {
        required,
        minLength: minLength(7),
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  mounted() {
    if (!store.getters["auth/isAuthenticated"]) {
      this.$router.push("/change-email/1");
    }
  },
  methods: {
    async newEmail() {
      this.loading = true;
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        logging.sendLog("FormSubmitted", 3);
        await mix_panel.onBasicEvent("FormSubmitted", "");
      } else {
        logging.sendLog("Form invalid", 3);
        await mix_panel.onBasicEvent("FormInvalid", "");
      }
      this.loading = false;
    },
  },
};
