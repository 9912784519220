<template>
  <div class="main">
    <b-container fluid class="h-100">
      <b-row class="justify-content-center h-100">
        <b-col cols="12">
          <slot name="content" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Signup",
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  min-height: 100%;
  width: 100%;
  background: #efeef2;
  // background: red;
}
</style>
