<template>
  <div class="card pt-4 pb-4 pr-5 pl-5">
    <div>
      <div class="card-content-title">
        <slot name="header" />
      </div>
      <div class="card-content mt-2">
        <slot name="content" />
      </div>
      <div class="card-content-footer d-flex mt-4">
        <slot name="footer" />
      </div>
    </div>
    <slot name="absolute-components" />
  </div>
</template>

<script>
export default {
  name: "BaseCard",
};
</script>

<style lang="scss" scoped>
.card {
  max-width: 522px;
  height: fit-content;
  background: #ffffff;
  border: 2px solid rgba(86, 64, 178, 0.15);
  box-sizing: border-box;
  border-radius: 20px;
  &-content {
    font-size: 18px;
    line-height: 24px;
    &-title {
      font-size: 24px;
      font-weight: bold;
    }
    &-footer {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>
