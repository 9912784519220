<template>
  <LoopLeft class="h-100">
    <template>
      <b-row
        class="mt-3 justify-content-center intro-padding text-center h-100"
      >
        <b-col xl="6" lg="7" md="10" sm="12">
          <div v-if="verifying && !failed">
            <div class="main-title">Verifying ...</div>
          </div>
          <div v-else>
            <div v-if="failed">
              <div class="main-title subtitle-red" id="mainTitleFailed">
                Verification failed
              </div>
              <div class="subtitle mt-4">
                Verification link is no longer valid. Click the button below to
                get a new link.
              </div>
            </div>
            <div v-else>
              <div
                class="main-title-small subtitle-green"
                id="mainTitleSuccess"
              >
                Success, your email was verified! 🎉
              </div>
              <div class="subtitle mt-4">Redirecting you to Loop...</div>
            </div>
            <SubmitButton
              v-if="!failed && !verifying"
              class="mt-5"
              text="Open Loop"
              @onClick="backToTheApp"
            />
            <SubmitButton
              v-else-if="failed && !verifying"
              class="mt-5"
              :loading="loading"
              id="resendVerificationEmail"
              text="Resend verification email"
              @onClick="resendVerificationEmail"
            />
          </div>
        </b-col>
      </b-row>
      <ToastSuccess size="large">
        <div>
          We've sent an email to
          <b>{{ email }}</b>
          <br />
          Please check your email and verify your account to continue. If you
          can't find our email, please check your "Spam" folder.
        </div>
      </ToastSuccess>
      <ToastError
        text="There was a problem sending an email. Please try again later."
      >
        <template>
          <div>Error: {{ toastMessage }}</div>
        </template>
      </ToastError>
    </template>
  </LoopLeft>
</template>

<script>
import SubmitButton from "@/components/buttons/SubmitButton";
import forgotPassword from "@/mixins/loopLogin/forgotPassword";
import ToastSuccess from "@/components/toast/ToastSuccess";
import verify from "@/mixins/loopLogin/verify";
import ToastError from "@/components/toast/ToastError";
import LoopLeft from "@/layout/LoopLeft";
export default {
  name: "index",
  components: {
    LoopLeft,
    ToastError,
    ToastSuccess,
    SubmitButton,
  },
  mixins: [forgotPassword, verify],
};
</script>
