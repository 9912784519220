<template>
  <div>
    <div class="card-layout d-flex flex-column">
      <div class="flex-shrink-0">
        <b-row class="text-center">
          <b-col>
            <div v-if="!isInvite">
              <div class="title">Try out Loop with your work email</div>
            </div>

            <CheckMarks
              :checkMarksArray="[
                '14-day trial',
                '1-minute setup',
                'Cancel any time',
              ]"
            />

            <!-- Google -->
            <SubmitButton
              :custom-class="'custom-button custom-button-social custom-button-social-google w-100'"
              text="Sign up with Google"
              @onClick="googleLogin(form.email)"
              id="submitGoogle"
            >
              <template #button-image>
                <img
                  src="@/assets/img/social_buttons/google_logo.svg"
                  rounded="circle"
                  width="26px"
                  height="26px"
                  alt="Circle image"
                />
              </template>
            </SubmitButton>

            <!-- Microsoft -->
            <SubmitButton
              :custom-class="'custom-button custom-button-social custom-button-social-microsoft w-100'"
              text="Sign up with Office 365"
              @onClick="microsoftLogin(form.email)"
              id="submitMicrosoft"
            >
              <template #button-image>
                <img
                  src="@/assets/img/social_buttons/microsoft_logo.svg"
                  rounded="circle"
                  width="26px"
                  height="26px"
                  alt="Circle image"
                />
              </template>
            </SubmitButton>

            <div class="separator">
              <div>or</div>
            </div>

            <label for="email">Enter your work email</label>
            <EmailInput
              id="email"
              :field="$v.form.email"
              :checkDomain="firstTry"
              :state="validateState($v.form, 'email')"
              :server-errors="serverErrors['email']"
              @update="$v.form.email.$model = $event"
              @keyupEnter="register"
              field-name="email"
              place-holder="name@work-email.com"
            />
            <b-row v-if="!firstTry">
              <b-col cols="6">
                <StringInput
                  id="firstName"
                  :field="$v.form.firstName"
                  :state="validateState($v.form, 'firstName')"
                  :server-errors="serverErrors['firstName']"
                  @update="$v.form.firstName.$model = $event"
                  @keyupEnter="register"
                  field-name="first name"
                  place-holder="First name"
                />
              </b-col>
              <b-col>
                <StringInput
                  id="lastName"
                  :field="$v.form.lastName"
                  :state="validateState($v.form, 'lastName')"
                  :server-errors="serverErrors['lastName']"
                  @update="$v.form.lastName.$model = $event"
                  @keyupEnter="register"
                  field-name="last name"
                  place-holder="Last name"
                />
              </b-col>
            </b-row>
            <PasswordInput
              v-if="!firstTry"
              id="password"
              :field="$v.form.password"
              :state="validateState($v.form, 'password')"
              :server-errors="serverErrors['password']"
              @update="$v.form.password.$model = $event"
              @keyupEnter="register"
              field-name="password"
              place-holder="Create your password"
            />
            <SubmitButton
              :text="
                firstTry
                  ? 'Sign up with your work email'
                  : 'Finish creating your account'
              "
              @onClick="register"
              :loading="loading"
              class="mt-1 mb-4"
              id="submitButton"
            />
          </b-col>
        </b-row>
      </div>

      <b-row class="text-center">
        <b-col>
          <div class="footer-copy">
            By signing up you accept Loop's
            <a id="termOfUse" href="https://www.intheloop.io/terms-of-use/">
              Terms of use
            </a>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="mt-3 footer-copy text-center">
      Already have an account?
      <a id="signIn" href="https://app.intheloop.io">Sign in</a>
    </div>
  </div>
</template>

<script>
import CheckMarks from "../checkMarks/CheckMarks";
import SubmitButton from "../buttons/SubmitButton";
import register from "@/mixins/loopLogin/register";
import PasswordInput from "../inputs/PasswordInput";
import EmailInput from "../inputs/EmailInput";
import oauthLogin from "../../mixins/oauth/oauthLogin";
import StringInput from "../inputs/StringInput";
export default {
  name: "RegistrationCard",
  components: {
    StringInput,
    EmailInput,
    PasswordInput,
    CheckMarks,
    SubmitButton,
  },
  mixins: [register, oauthLogin],
  props: {
    isInvite: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  created() {
    const authStrategy = this.$route.query.oauth;

    if (!authStrategy) {
      return;
    }

    authStrategy === "microsoft"
      ? this.microsoftLogin(this.$route.query.login_hint)
      : this.googleLogin(this.$route.query.login_hint);
  },
};
</script>
